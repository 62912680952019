import * as actionTypes from "./actionTypes";

export const setCurrentModule = (moduleName) => {
  return { type: actionTypes.SET_CURRENT_MODULE, moduleName };
};

export const setCurrentSubModule = (subModuleName) => {
  return { type: actionTypes.SET_CURRENT_SUB_MODULE, subModuleName };
};

export const setCurrentSeller = (moduleName) => {
  return { type: actionTypes.SET_CURRENT_MODULE, moduleName };
};

export const setCurrentProject = (projectName) => {
  return { type: actionTypes.SET_CURRENT_PROJECT, projectName };
};

export const setCurrentSow = (sowName) => {
  return { type: actionTypes.SET_CURRENT_SOW, sowName };
};

export const setCurrentTask = (taskName) => {
  return { type: actionTypes.SET_CURRENT_TASK, taskName };
};
