import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import Loader from "../../components/UI/Loader";
import DataTable from "../../components/Tables/DataTable";
import TableActions from "../../components/Buttons/TableActions";
import ChangeTitle from "../../components/UI/ChangeTitle";
import AlertDismissible from "../../components/Alert/AlertDismissible";
import DropdownConfirmDeleteBtn from "../../components/UI/DropdownConfirmDeleteBtn";
import { setCurrentProject } from "../../store/actions/paramsActions";
import UpdateParamNameAndGo from "../../components/Buttons/UpdateParamNameAndGo";

const Projects = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [projectsState, setProjectsState] = useState([]);
  const [responseMessage, setResponseMessage] = useState();
  const [errorState, setErrorState] = useState();

  const fetchProjects = () => {
    setIsLoading(true);
    axios
      .get("/projects")
      .then((res) => {
        setProjectsState(res.data.projects);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteProjectHandler = (projectId) => {
    setErrorState(false);
    setResponseMessage(false);
    axios
      .delete(`/projects/${projectId}`)
      .then((res) => {
        setIsLoading(true);
        setResponseMessage(res.data.msg);
        fetchProjects();
      })
      .catch((err) => {
        if (err.response) {
          setErrorState(err.response.data.errors[0]);
        }
      });
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <>
      <ChangeTitle
        title="Projects"
        buttonTitle="Add a project"
        buttonPath="/add-project"
        buttonSmallIcon="plus"
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <AlertDismissible>{responseMessage}</AlertDismissible>
          <AlertDismissible onClose={() => setErrorState(false)} danger>
            {errorState}
          </AlertDismissible>
          <DataTable titles={["Name", "Description", "Actions"]}>
            {projectsState.map((el) => {
              return (
                <tr key={el.id}>
                  <td>
                    <Link to={`/sows/${el.id}`}>{el.name}</Link>
                  </td>
                  <td>{el.description}</td>
                  <td>
                    <TableActions>
                      <UpdateParamNameAndGo
                        paramSetter={setCurrentProject}
                        title="Update"
                        paramName={el.name}
                        path={`/update-project/${el.id}`}
                      />
                      <DropdownConfirmDeleteBtn
                        deleteConfirmed={() => deleteProjectHandler(el.id)}
                      />
                    </TableActions>
                  </td>
                </tr>
              );
            })}
          </DataTable>
        </>
      )}
    </>
  );
};

export default Projects;
