import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Dropdown } from "react-bootstrap";

import Loader from "../../components/UI/Loader";
import DataTable from "../../components/Tables/DataTable";
import TableActions from "../../components/Buttons/TableActions";
import ChangeTitle from "../../components/UI/ChangeTitle";
import AlertDismissible from "../../components/Alert/AlertDismissible";
import DropdownConfirmDeleteBtn from "../../components/UI/DropdownConfirmDeleteBtn";
import { setCurrentTask } from "../../store/actions/paramsActions";
import UpdateParamNameAndGo from "../../components/Buttons/UpdateParamNameAndGo";
import Select from "../../components/FormElements/Select";
import Constants from "../../constants/constants";
import FormGroup from "../../components/FormElements/FormGroup";
import isEmpty from "../../utils/is-empty";
import getUrlParams from "../../utils/get-url-params";

const Tasks = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [tasksState, setTasksState] = useState([]);
  const [statusesState, setStatusesState] = useState({});
  const [statusFilterState, setStatusFilterState] = useState("");
  const [responseMessage, setResponseMessage] = useState();
  const [errorState, setErrorState] = useState();
  const [sowIdState, setSowIdState] = useState(getUrlParams("sowId"));

  const fetchTasks = useCallback(() => {
    setIsLoading(true);
    axios
      .get("/tasks")
      .then((res) => {
        let { tasks } = res.data;

        if (statusFilterState) {
          tasks = tasks.filter((el) => {
            if (isEmpty(el.taskStatuses)) {
              return false;
            } else {
              return el.taskStatuses[0].status === statusFilterState;
            }
          });
        }

        if (sowIdState) {
          tasks = tasks.filter((el) => {
            return el.sowId === parseInt(sowIdState);
          });
        }

        setTasksState(tasks);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [statusFilterState, sowIdState]);

  const toggleStatusEditableHandler = (taskId) => {
    setIsLoading(true);
    setResponseMessage(false);
    setErrorState(false);
    axios
      .put(`/tasks/statuses/${taskId}`)
      .then((res) => {
        setIsLoading(true);
        setResponseMessage(res.data.msg);
        fetchTasks();
      })
      .catch((err) => {
        if (err.response) {
          setErrorState(err.response.data.errors[0]);
        }
      });
  };

  const inputChangeHandler = (e, taskId) => {
    let { value } = e.target;

    setErrorState(false);
    setResponseMessage(false);

    axios
      .post(`/tasks/statuses/${taskId}`, { status: e.target.value })
      .then((res) => {
        setResponseMessage(res.data.msg);

        setStatusesState((prevState) => {
          return { ...prevState, [taskId]: value };
        });
      })
      .catch((err) => {
        if (err.response) {
          setErrorState(err.response.data.errors[0]);
        } else {
          setErrorState("Something's not right");
        }
      });
  };

  const deleteTaskHandler = (taskId) => {
    setErrorState(false);
    setResponseMessage(false);
    axios
      .delete(`/tasks/${taskId}`)
      .then((res) => {
        setIsLoading(true);
        setResponseMessage(res.data.msg);
        fetchTasks();
      })
      .catch((err) => {
        if (err.response) {
          setErrorState(err.response.data.errors[0]);
        }
      });
  };

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  useEffect(() => {
    const statuses = {};

    tasksState.forEach((el) => {
      statuses[el.id] = el.taskStatuses[0]?.status;
    });

    setStatusesState(statuses);
  }, [tasksState, sowIdState]);

  useEffect(() => {
    setSowIdState(getUrlParams("sowId"));
  }, []);

  return (
    <>
      <ChangeTitle
        title="Tasks"
        buttonTitle="Add a task"
        buttonPath="/add-task"
        buttonSmallIcon="plus"
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <AlertDismissible>{responseMessage}</AlertDismissible>
          <AlertDismissible onClose={() => setErrorState(false)} danger>
            {errorState}
          </AlertDismissible>

          <FormGroup label="Status *">
            <Select
              firstEmpty
              name="status"
              value={statusFilterState}
              onChange={(e) => setStatusFilterState(e.target.value)}
              options={Constants.validStatuses.map((el) => {
                return { text: el, value: el };
              })}
            />
          </FormGroup>

          <DataTable titles={["Title", "Status", "Actions"]}>
            {tasksState.map((el) => {
              return (
                <tr key={el.id}>
                  <td>{el.task}</td>
                  <td>
                    <Select
                      style={{ width: "15rem" }}
                      firstEmpty
                      name="status"
                      value={statusesState[el.id]}
                      onChange={(e) => inputChangeHandler(e, el.id)}
                      options={Constants.validStatuses.map((el) => {
                        return { text: el, value: el };
                      })}
                    />
                  </td>
                  <td>
                    <TableActions>
                      <UpdateParamNameAndGo
                        paramSetter={setCurrentTask}
                        paramName={el.name}
                        title="View attachment/s"
                        path={`/view-task-attachments/${el.id}`}
                      />
                      <UpdateParamNameAndGo
                        paramSetter={setCurrentTask}
                        paramName={el.name}
                        title="Add attachment/s"
                        path={`/add-task-attachment/${el.id}`}
                      />
                      <UpdateParamNameAndGo
                        paramSetter={setCurrentTask}
                        paramName={el.name}
                        title="Statuses"
                        path={`/task-statuses/${el.id}`}
                      />
                      <Dropdown.Item
                        onClick={() => toggleStatusEditableHandler(el.id)}
                      >
                        {el.isStatusEditable
                          ? "Set uneditable"
                          : "Set editable"}
                      </Dropdown.Item>
                      <UpdateParamNameAndGo
                        paramSetter={setCurrentTask}
                        paramName={el.name}
                        title="Update"
                        path={`/update-task/${el.id}`}
                      />
                      <DropdownConfirmDeleteBtn
                        deleteConfirmed={() => deleteTaskHandler(el.id)}
                      />
                    </TableActions>
                  </td>
                </tr>
              );
            })}
          </DataTable>
        </>
      )}
    </>
  );
};

export default Tasks;
