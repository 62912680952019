import axios from "axios";
import jwt_decode from "jwt-decode";
import setAxiosAuthHeader from "../../utils/set-axios-auth-header";

import * as actionTypes from "./actionTypes";

export const authStart = () => {
  return { type: actionTypes.AUTH_START };
};

export const authSuccess = (token, user, msg) => {
  if (token) {
    // Login
    setAxiosAuthHeader(token);
    return { type: actionTypes.AUTH_SUCCESS, token, user };
  } else {
    // Signup
    return { type: actionTypes.AUTH_SUCCESS, user, msg };
  }
};

export const authFail = (errors) => {
  return { type: actionTypes.AUTH_FAIL, errors };
};

export const authClearErrors = () => {
  return { type: actionTypes.AUTH_CLEAR_ERRORS };
};

export const logout = () => {
  // Empty function call removes the header
  setAxiosAuthHeader();
  localStorage.removeItem("token");
  localStorage.removeItem("expirationDate");
  localStorage.removeItem("userId");
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000); // converted to milliseconds
  };
};

export const auth = (authData, isLogin) => (dispatch) => {
  dispatch(authStart());
  let url = "/users/register";
  if (isLogin) {
    url = "/users/login";
  }
  axios
    .post(url, authData)
    .then((res) => {
      if (isLogin) {
        const decodedToken = jwt_decode(res.data.token);
        const expiresIn = decodedToken.exp - Date.now() / 1000; // time in seconds
        const expirationDate = new Date(
          new Date().getTime() + expiresIn * 1000
        );
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("expirationDate", expirationDate);
        localStorage.setItem("userId", JSON.stringify(res.data.user.id));
        dispatch(authSuccess(res.data.token, res.data.user));
        dispatch(checkAuthTimeout(expiresIn));
      } else {
        dispatch(authSuccess(null, res.data.user, res.data.msg));
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(authFail(err.response.data.errors));
      } else {
        dispatch(authFail([err.message]));
      }
    });
};

export const authCheckState = () => (dispatch) => {
  const token = localStorage.getItem("token");
  if (!token) {
    dispatch(logout());
  } else {
    const expirationDate = new Date(localStorage.getItem("expirationDate"));
    if (expirationDate <= new Date()) {
      dispatch(logout());
    } else {
      const userId = JSON.parse(localStorage.getItem("userId"));
      setAxiosAuthHeader(token);
      axios
        .get(`/users/${userId}`)
        .then((res) => {
          dispatch(authSuccess(token, res.data.user));
          dispatch(
            checkAuthTimeout(
              (expirationDate.getTime() - new Date().getTime()) / 1000
            )
          );
        })
        .catch((err) => {
          dispatch(logout());
          if (err.response) {
            dispatch(authFail(err.response.data.errors));
          }
        });
    }
  }
};
