import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import copy from "copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";
import { BitlyClient } from "bitly-react";

import Loader from "../../components/UI/Loader";
import DataTable from "../../components/Tables/DataTable";
import TableActions from "../../components/Buttons/TableActions";
import ChangeTitle from "../../components/UI/ChangeTitle";
import AlertDismissible from "../../components/Alert/AlertDismissible";
import DropdownConfirmDeleteBtn from "../../components/UI/DropdownConfirmDeleteBtn";
import { setCurrentSow } from "../../store/actions/paramsActions";
import UpdateParamNameAndGo from "../../components/Buttons/UpdateParamNameAndGo";
import Constants from "../../constants/constants";

const bitly = new BitlyClient("58f98003d6bd31c042985029405089a39c79d6c9", {});

const SOWs = () => {
  const { projectId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [sowsState, setSowsState] = useState([]);
  const [responseMessage, setResponseMessage] = useState();
  const [errorState, setErrorState] = useState();

  const fetchSOWs = useCallback(() => {
    setIsLoading(true);
    axios
      .get(`/sows/${projectId ? `projects/${projectId}` : ""}`)
      .then((res) => {
        setSowsState(res.data.sows);
      })
      .catch((err) => {
        if (err.response) {
          setErrorState(err.response.data.errors[0]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [projectId]);

  const copySowLinkHandler = async (url) => {
    let result;

    try {
      result = await bitly.shorten(
        `${Constants.defaultFrontEndHost}?id=${url || ""}`
      );
    } catch (e) {
      console.log(e);
      toast.error("Please try again!", {
        position: "top-left",
        autoClose: 2000,
      });
    }

    // copy(`${Constants.defaultFrontEndHost}?id=${url || ""}`);
    copy(result.url);
    toast.success("Link copied to clipboard!", {
      position: "top-left",
      autoClose: 2000,
    });
  };

  const deleteSowHandler = (sowId) => {
    setErrorState(false);
    setResponseMessage(false);
    axios
      .delete(`/sows/${sowId}`)
      .then((res) => {
        setIsLoading(true);
        setResponseMessage(res.data.msg);
        fetchSOWs();
      })
      .catch((err) => {
        if (err.response) {
          setErrorState(err.response.data.errors[0]);
        }
      });
  };

  useEffect(() => {
    fetchSOWs();
  }, [fetchSOWs]);

  return (
    <>
      <ToastContainer />
      <ChangeTitle
        title="Scope of works"
        buttonTitle="Add a scope of work"
        buttonPath="/add-sow"
        buttonSmallIcon="plus"
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <AlertDismissible>{responseMessage}</AlertDismissible>
          <AlertDismissible onClose={() => setErrorState(false)} danger>
            {errorState}
          </AlertDismissible>
          <DataTable titles={["Name", "Description", "Project", "Actions"]}>
            {sowsState.map((el) => {
              return (
                <tr key={el.id}>
                  <td>
                    {/* <Link to={`/modules/${el.id}`}> */}
                    {el.name}
                    {/* </Link> */}
                  </td>
                  <td>{el.description}</td>
                  <td>{el.project.name}</td>
                  <td>
                    <TableActions>
                      {projectId && (
                        <UpdateParamNameAndGo
                          paramSetter={setCurrentSow}
                          title="Add Task/s"
                          paramName={el.name}
                          path={`/add-task/${projectId}/${el.id}`}
                        />
                      )}
                      <UpdateParamNameAndGo
                        paramSetter={setCurrentSow}
                        title="View tasks"
                        paramName={el.name}
                        path={`/tasks?sowId=${el.id}`}
                      />
                      <UpdateParamNameAndGo
                        paramSetter={setCurrentSow}
                        title="Update"
                        paramName={el.name}
                        path={`/update-sow/${el.id}`}
                      />
                      <UpdateParamNameAndGo
                        paramSetter={setCurrentSow}
                        title="Status links"
                        paramName={el.name}
                        path={`/status-links/${el.url}`}
                      />
                      <Dropdown.Item onClick={() => copySowLinkHandler(el.url)}>
                        <span>Copy link</span>
                      </Dropdown.Item>
                      <DropdownConfirmDeleteBtn
                        deleteConfirmed={() => deleteSowHandler(el.id)}
                      />
                    </TableActions>
                  </td>
                </tr>
              );
            })}
          </DataTable>
        </>
      )}
    </>
  );
};

export default SOWs;
