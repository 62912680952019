import * as actionTypes from "../actions/actionTypes";

import isEmpty from "../../utils/is-empty";
import updateObject from "../../utils/update-object";

const initialState = {
  isAuthenticated: false,
  msg: null,
  token: "unset",
  user: null,
  errors: [],
  isLoading: null,
};

const authStart = (state, action) => {
  return updateObject(state, {
    errors: [],
    isLoading: true,
    msg: null,
  });
};

const authSuccess = (state, action) => {
  // Login
  if (!isEmpty(action.token)) {
    return updateObject(state, {
      isAuthenticated: !isEmpty(action.token),
      token: action.token,
      user: action.user,
      isLoading: false,
      errors: [],
    });
  } else {
    // Signup
    return updateObject(state, {
      isAuthenticated: false,
      isLoading: false,
      errors: [],
      msg: action.msg,
    });
  }
};

const updateUser = (state, action) => {
  return updateObject(state, {
    user: action.user,
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    errors: action.errors || [],
    isLoading: false,
    isAuthenticated: null,
  });
};

const authClearErrors = (state, action) => {
  return updateObject(state, { errors: [], msg: null });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    isAuthenticated: !isEmpty(action.token),
    token: null,
    user: null,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.UPDATE_USER:
      return updateUser(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_CLEAR_ERRORS:
      return authClearErrors(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    default:
      return state;
  }
};
export default reducer;
