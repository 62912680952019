import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import BreadCrumb from "../../components/UI/Breadcrumb";
import FormGroup from "../../components/FormElements/FormGroup";
import Input from "../../components/FormElements/Input";
import ChangeTitle from "../../components/UI/ChangeTitle";
import FormButton from "../../components/Buttons/FormButton";
import AlertDismissible from "../../components/Alert/AlertDismissible";
import Loader from "../../components/UI/Loader";

const initialState = { name: "", description: "" };

const AddUpdateProject = ({ mode }) => {
  const { projectId } = useParams();
  const [formState, setFormState] = useState(initialState);
  const [isLoading, setIsLoading] = useState(true);
  const [isBeingSubmitted, setIsBeingSubmitted] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [errorState, setErrorState] = useState("");

  let title = `${mode === "edit" ? "Update a" : "Add"} project`;

  const inputChangeHandler = (e, name, value) => {
    if (e) {
      ({ name, value } = e.target);
    }

    setFormState((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const formSubmitHandler = async (e) => {
    setIsBeingSubmitted(true);
    setResponseMessage(false);
    setErrorState(false);
    e.preventDefault();

    let res;
    try {
      if (mode === "edit") {
        res = await axios.patch(`/projects/${projectId}`, formState);
      } else {
        res = await axios.post("/projects", formState);
        setFormState(initialState);
      }
      setResponseMessage(res.data.msg);
    } catch (err) {
      console.log(err);
      if (err.response) {
        setErrorState(err.response.data.errors[0]);
      }
    }

    setIsBeingSubmitted(false);
  };

  useEffect(() => {
    if (mode === "edit") {
      axios
        .get(`/projects/${projectId}`)
        .then((res) => {
          const { project } = res.data;
          setFormState(project);
        })
        .catch((err) => {
          console.log(err);
          if (err.response) {
            return setErrorState(err.response.data.errors[0]);
          }
          setErrorState("Failed to fetch project");
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [projectId, mode]);

  return (
    <>
      <ChangeTitle
        title={title}
        buttonTitle="View projects"
        buttonPath={`/projects`}
        buttonSmallIcon="list"
      />
      <BreadCrumb
        showProjectName={mode === "edit"}
        items={[{ title: "Projects", path: "/projects" }, title]}
      />
      <AlertDismissible>{responseMessage}</AlertDismissible>
      <AlertDismissible onClose={() => setErrorState(false)} danger>
        {errorState}
      </AlertDismissible>

      {mode === "edit" && isLoading ? (
        <Loader />
      ) : (
        <form onSubmit={formSubmitHandler}>
          <FormGroup label="Name *">
            <Input
              type="text"
              name="name"
              placeholder="Name"
              value={formState.name || ""}
              onChange={inputChangeHandler}
            />
          </FormGroup>

          <FormGroup label="Description *">
            <Input
              textarea
              type="text"
              name="description"
              placeholder="Description"
              value={formState.description || ""}
              onChange={inputChangeHandler}
            />
          </FormGroup>

          <FormGroup>
            <FormButton disabled={isBeingSubmitted}>
              {mode === "edit" ? "Update" : "Add"}
            </FormButton>
          </FormGroup>
        </form>
      )}
    </>
  );
};

export default AddUpdateProject;
