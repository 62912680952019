import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";

import Loader from "../../components/UI/Loader";
import DataTable from "../../components/Tables/DataTable";
import TableActions from "../../components/Buttons/TableActions";
import ChangeTitle from "../../components/UI/ChangeTitle";
import AlertDismissible from "../../components/Alert/AlertDismissible";
import DropdownConfirmDeleteBtn from "../../components/UI/DropdownConfirmDeleteBtn";
import { setCurrentModule } from "../../store/actions/paramsActions";
import UpdateParamNameAndGo from "../../components/Buttons/UpdateParamNameAndGo";

const Modules = () => {
  const { sowId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [modulesState, setModulesState] = useState([]);
  const [responseMessage, setResponseMessage] = useState();
  const [errorState, setErrorState] = useState();

  const fetchModules = useCallback(() => {
    setIsLoading(true);
    axios
      .get(`/modules${sowId ? `/sows/${sowId}` : ""}`)
      .then((res) => {
        setModulesState(res.data.modules);
      })
      .catch((err) => {
        if (err.response) {
          setErrorState(err.response.data.errors[0]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [sowId]);

  const deleteModuleHandler = (moduleId) => {
    setErrorState(false);
    setResponseMessage(false);
    axios
      .delete(`/modules/${moduleId}`)
      .then((res) => {
        setIsLoading(true);
        setResponseMessage(res.data.msg);
        fetchModules();
      })
      .catch((err) => {
        if (err.response) {
          setErrorState(err.response.data.errors[0]);
        }
      });
  };

  useEffect(() => {
    fetchModules();
  }, [fetchModules]);

  return (
    <>
      <ChangeTitle
        title="Modules"
        buttonTitle="Add a module"
        buttonPath="/add-module"
        buttonSmallIcon="plus"
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <AlertDismissible>{responseMessage}</AlertDismissible>
          <AlertDismissible onClose={() => setErrorState(false)} danger>
            {errorState}
          </AlertDismissible>
          <DataTable titles={["Name", "Scope of work", "Project", "Actions"]}>
            {modulesState.map((el) => {
              return (
                <tr key={el.id}>
                  <td>{el.name}</td>
                  <td>{el.sow.name}</td>
                  <td>{el.sow?.project?.name}</td>
                  <td>
                    <TableActions>
                      <UpdateParamNameAndGo
                        paramSetter={setCurrentModule}
                        title="Update"
                        paramName={el.name}
                        path={`/update-module/${el.id}`}
                      />
                      <DropdownConfirmDeleteBtn
                        deleteConfirmed={() => deleteModuleHandler(el.id)}
                      />
                    </TableActions>
                  </td>
                </tr>
              );
            })}
          </DataTable>
        </>
      )}
    </>
  );
};

export default Modules;
