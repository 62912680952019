import React, { useEffect } from "react";
import "./App.css";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import Constants from "./constants/constants";
import Routes from "./Routes";
import "react-toastify/dist/ReactToastify.css";
import { authCheckState } from "./store/actions/authActions";

axios.defaults.baseURL = Constants.defaultAPIHost;

function App() {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(authCheckState());
  }, [dispatch]);

  return <>{token !== "unset" && <Routes />}</>;
}

export default App;
