import React from "react";

const Select = (props) => {
  const { firstEmpty, options, innerRef, ...rest } = props;

  return (
    <select ref={innerRef} className="form-control" {...rest}>
      {firstEmpty && <option value="">Please select</option>}
      {options.map((el) => {
        return (
          <option key={(el.value || "") + Math.random()} value={el.value}>
            {el.text}
          </option>
        );
      })}
    </select>
  );
};

export default Select;
