import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import DataTable from "../../components/Tables/DataTable";
import BreadCrumb from "../../components/UI/Breadcrumb";
import FormGroup from "../../components/FormElements/FormGroup";
import Input from "../../components/FormElements/Input";
import ChangeTitle from "../../components/UI/ChangeTitle";
import FormButton from "../../components/Buttons/FormButton";
import AlertDismissible from "../../components/Alert/AlertDismissible";
import SideBySide from "../../components/UI/SideBySide";
import Loader from "../../components/UI/Loader";

const AddTaskFinal = ({ mode }) => {
  const { projectId, sowId, moduleId, subModuleId } = useParams();
  const [associatedFieldsState, setAssociatedFieldsState] = useState([]);
  const [formState, setFormState] = useState({
    projectId,
    sowId,
    moduleId,
    subModuleId,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isBeingSubmitted, setIsBeingSubmitted] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [taskListIsLoading, setTaskListIsLoading] = useState(true);
  const [tasksState, setTasksState] = useState([]);
  const [errorState, setErrorState] = useState("");

  let title = `${mode === "edit" ? "Update a" : "Add"} task ${
    mode === "edit" ? "" : "(Step: 2/2)"
  }`;

  const inputChangeHandler = (e, name, value) => {
    if (e) {
      ({ name, value } = e.target);
    }

    setFormState((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const formSubmitHandler = async (e) => {
    setIsBeingSubmitted(true);
    setResponseMessage(false);
    setErrorState(false);
    e.preventDefault();

    let res;
    try {
      res = await axios.post("/tasks", formState);
    } catch (err) {
      setIsBeingSubmitted(false);
      console.log(err);
      if (err.response) {
        return setErrorState(err.response.data.errors[0]);
      }
    }
    setFormState((prevState) => {
      return { ...prevState, task: "", description: "" };
    });

    setIsBeingSubmitted(false);
    setResponseMessage(res.data.msg);
    fetchTasks();
  };

  const fetchTasks = () => {
    setTaskListIsLoading(true);
    axios
      .get("/tasks")
      .then((res) => {
        setTasksState(res.data.tasks);
      })
      .catch((err) => {
        if (err.response) {
          setErrorState(err.response.data.errors[0]);
        }
      })
      .finally(() => {
        setTaskListIsLoading(false);
      });
  };

  useEffect(() => {
    const fetchMyAPI = async () => {
      try {
        const res = await axios.post(`/tasks/associated-fields/`, {
          projectId: formState.projectId,
          sowId: formState.sowId,
          moduleId: formState.moduleId,
          subModuleId: formState.subModuleId,
        });
        const { project, sow, module, subModule } = res.data;
        setAssociatedFieldsState({ project, sow, module, subModule });
        setIsLoading(false);
      } catch (err) {
        console.log(err);
        if (err.response) {
          return setErrorState(err.response.data.errors[0]);
        }
        setErrorState(`Failed to fetch associated data`);
      }
    };

    fetchMyAPI();
  }, [
    formState.projectId,
    formState.sowId,
    formState.moduleId,
    formState.subModuleId,
  ]);

  useEffect(() => {
    fetchTasks();
  }, []);

  return (
    <>
      <ChangeTitle
        title={title}
        buttonTitle="View tasks"
        buttonPath={`/tasks`}
        buttonSmallIcon="list"
      />
      <BreadCrumb
        items={[
          { title: "Tasks", path: "/tasks" },
          {
            title: "Add task (Step: 1/2)",
            path: `/add-task/${projectId}/${sowId}`,
          },
          title,
        ]}
      />
      <AlertDismissible>{responseMessage}</AlertDismissible>
      <AlertDismissible onClose={() => setErrorState(false)} danger>
        {errorState}
      </AlertDismissible>

      {isLoading ? (
        <Loader />
      ) : (
        <div className="pb-5">
          <div className="mb-5">
            <SideBySide
              left="Project"
              right={associatedFieldsState?.project?.name}
            />
            <SideBySide
              left="Scope of work"
              right={associatedFieldsState?.sow?.name}
            />
            <SideBySide
              left="Module"
              right={associatedFieldsState?.module?.name}
            />
            <SideBySide
              left="Sub module"
              right={associatedFieldsState?.subModule?.name}
            />
          </div>

          <form onSubmit={formSubmitHandler}>
            <FormGroup label="Task *">
              <Input
                type="text"
                name="task"
                placeholder="Task"
                value={formState.task || ""}
                onChange={inputChangeHandler}
              />
            </FormGroup>

            <FormGroup label="Description">
              <Input
                textarea
                type="text"
                name="description"
                placeholder="Description"
                value={formState.description || ""}
                onChange={inputChangeHandler}
              />
            </FormGroup>

            <FormGroup>
              <FormButton disabled={isBeingSubmitted}>Add</FormButton>
            </FormGroup>
          </form>
        </div>
      )}

      {taskListIsLoading ? (
        <Loader />
      ) : (
        <div className="mt-5">
          <DataTable
            ordering={false}
            titles={[
              "Title",
              "Description",
              "Project",
              "Scope of work",
              "Module",
              "Sub module",
              "Created At",
            ]}
          >
            {tasksState.map((el) => {
              return (
                <tr key={el.id}>
                  <td>{el.task}</td>
                  <td>{el.description}</td>
                  <td>{el.project.name}</td>
                  <td>{el.sow.name}</td>
                  <td>{el.module?.name}</td>
                  <td>{el.subModule?.name}</td>
                  <td>{new Date(el.createdAt).toLocaleDateString()}</td>
                </tr>
              );
            })}
          </DataTable>
        </div>
      )}
    </>
  );
};

export default AddTaskFinal;
