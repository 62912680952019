import React, { useEffect, useState } from "react";
import axios from "axios";

import Loader from "../../components/UI/Loader";
import DataTable from "../../components/Tables/DataTable";
import TableActions from "../../components/Buttons/TableActions";
import ChangeTitle from "../../components/UI/ChangeTitle";
import AlertDismissible from "../../components/Alert/AlertDismissible";
import DropdownConfirmDeleteBtn from "../../components/UI/DropdownConfirmDeleteBtn";
import { setCurrentSubModule } from "../../store/actions/paramsActions";
import UpdateParamNameAndGo from "../../components/Buttons/UpdateParamNameAndGo";

const SubModules = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [subModulesState, setSubModulesState] = useState([]);
  const [responseMessage, setResponseMessage] = useState();
  const [errorState, setErrorState] = useState();

  const fetchSubModules = () => {
    setIsLoading(true);
    axios
      .get("/sub-modules")
      .then((res) => {
        setSubModulesState(res.data.subModules);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteSubModuleHandler = (subModuleId) => {
    setErrorState(false);
    setResponseMessage(false);
    axios
      .delete(`/sub-modules/${subModuleId}`)
      .then((res) => {
        setIsLoading(true);
        setResponseMessage(res.data.msg);
        fetchSubModules();
      })
      .catch((err) => {
        if (err.response) {
          setErrorState(err.response.data.errors[0]);
        }
      });
  };

  useEffect(() => {
    fetchSubModules();
  }, []);

  return (
    <>
      <ChangeTitle
        title="Sub modules"
        buttonTitle="Add a sub module"
        buttonPath="/add-sub-module"
        buttonSmallIcon="plus"
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <AlertDismissible>{responseMessage}</AlertDismissible>
          <AlertDismissible onClose={() => setErrorState(false)} danger>
            {errorState}
          </AlertDismissible>
          <DataTable titles={["Name", "Module", "Actions"]}>
            {subModulesState.map((el) => {
              return (
                <tr key={el.id}>
                  <td>{el.name}</td>
                  <td>{el.module.name}</td>
                  <td>
                    <TableActions>
                      <UpdateParamNameAndGo
                        paramSetter={setCurrentSubModule}
                        title="Update"
                        paramName={el.name}
                        path={`/update-sub-module/${el.id}`}
                      />
                      <DropdownConfirmDeleteBtn
                        deleteConfirmed={() => deleteSubModuleHandler(el.id)}
                      />
                    </TableActions>
                  </td>
                </tr>
              );
            })}
          </DataTable>
        </>
      )}
    </>
  );
};

export default SubModules;
