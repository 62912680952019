import $ from "jquery";
import jQuery from "jquery";
import PerfectScrollbar from "perfect-scrollbar";

$.metisMenu = require("metismenu");

export function initNav() {
  // ////////////////////////////////////////////////////

  $("#menu").metisMenu();
  // ////////////////////////////////////////////////////

  var screenWidth = $(window).width();

  jQuery(window).on("load", function () {
    $("#preloader").fadeOut(500);
    $("#main-wrapper").addClass("show");

    // if($('body').attr('data-sidebar-position') === "fixed") {
    //     $('.deznav-scroll').slimscroll({
    //         position: "right",
    //         size: "5px",
    //         height: "100%",
    //         color: "transparent"
    //     });
    // }
    // $("select").selectpicker();
  });

  var body = $("body");
  var html = $("html");

  function dezSettings({
    typography,
    version,
    layout,
    navheaderBg,
    headerBg,
    sidebarStyle,
    sidebarBg,
    sidebarPosition,
    headerPosition,
    containerLayout,
    direction,
    primary,
  }) {
    this.typography = typography || "roboto";
    this.version = version || "light";
    this.layout = layout || "vertical";
    this.navheaderBg = navheaderBg || "color_1";
    this.headerBg = headerBg || "color_1";
    this.sidebarStyle = sidebarStyle || "full";
    this.sidebarBg = sidebarBg || "color_1";
    this.sidebarPosition = sidebarPosition || "static";
    this.headerPosition = headerPosition || "static";
    this.containerLayout = containerLayout || "wide";
    this.direction = direction || "ltr";
    this.primary = primary || "color_1";

    this.manageTypography();
    this.manageVersion();
    this.manageLayout();
    this.manageNavHeaderBg();
    this.manageHeaderBg();
    this.manageSidebarStyle();
    this.manageSidebarBg();
    this.manageSidebarPosition();
    this.manageHeaderPosition();
    this.manageContainerLayout();
    this.manageRtlLayout();
    this.manageResponsiveSidebar();
    this.managePrimaryColor();
  }
  dezSettings.prototype.manageVersion = function () {
    switch (this.version) {
      case "light":
        body.attr("data-theme-version", "light");
        break;
      case "dark":
        body.attr("data-theme-version", "dark");
        break;
      case "transparent":
        body.attr("data-theme-version", "transparent");
        break;
      default:
        body.attr("data-theme-version", "light");
    }
  };
  dezSettings.prototype.manageTypography = function () {
    switch (this.typography) {
      case "poppins":
        body.attr("data-typography", "poppins");
        break;
      case "roboto":
        body.attr("data-typography", "roboto");
        break;
      case "opensans":
        body.attr("data-typography", "opensans");
        break;
      case "helveticaneue":
        body.attr("data-typography", "helveticaneue");
        break;
      default:
        body.attr("data-typography", "roboto");
    }
  };
  dezSettings.prototype.manageLayout = function () {
    switch (this.layout) {
      case "horizontal":
        this.sidebarStyle === "overlay"
          ? body.attr("data-sidebar-style", "full")
          : body.attr("data-sidebar-style", `${this.sidebarStyle}`);
        body.attr("data-layout", "horizontal");
        break;
      case "vertical":
        body.attr("data-layout", "vertical");
        break;
      default:
        body.attr("data-layout", "vertical");
    }
  };
  dezSettings.prototype.manageNavHeaderBg = function () {
    switch (this.navheaderBg) {
      case "color_1":
        body.attr("data-nav-headerbg", "color_1");
        break;
      case "color_2":
        body.attr("data-nav-headerbg", "color_2");
        break;
      case "color_3":
        body.attr("data-nav-headerbg", "color_3");
        break;
      case "color_4":
        body.attr("data-nav-headerbg", "color_4");
        break;
      case "color_5":
        body.attr("data-nav-headerbg", "color_5");
        break;
      case "color_6":
        body.attr("data-nav-headerbg", "color_6");
        break;
      case "color_7":
        body.attr("data-nav-headerbg", "color_7");
        break;
      case "color_8":
        body.attr("data-nav-headerbg", "color_8");
        break;
      case "color_9":
        body.attr("data-nav-headerbg", "color_9");
        break;
      case "color_10":
        body.attr("data-nav-headerbg", "color_10");
        break;
      case "color_11":
        body.attr("data-nav-headerbg", "color_11");
        break;
      case "color_12":
        body.attr("data-nav-headerbg", "color_12");
        break;
      case "color_13":
        body.attr("data-nav-headerbg", "color_13");
        break;
      case "color_14":
        body.attr("data-nav-headerbg", "color_14");
        break;
      case "color_15":
        body.attr("data-nav-headerbg", "color_15");
        break;
      case "image_1":
        body.attr("data-nav-headerbg", "image_1");
        break;
      case "image_2":
        body.attr("data-nav-headerbg", "image_2");
        break;
      case "image_3":
        body.attr("data-nav-headerbg", "image_3");
        break;
      default:
        body.attr("data-nav-headerbg", "color_1");
    }
  };
  dezSettings.prototype.manageHeaderBg = function () {
    switch (this.headerBg) {
      case "color_1":
        body.attr("data-headerbg", "color_1");
        break;
      case "color_2":
        body.attr("data-headerbg", "color_2");
        break;
      case "color_3":
        body.attr("data-headerbg", "color_3");
        break;
      case "color_4":
        body.attr("data-headerbg", "color_4");
        break;
      case "color_5":
        body.attr("data-headerbg", "color_5");
        break;
      case "color_6":
        body.attr("data-headerbg", "color_6");
        break;
      case "color_7":
        body.attr("data-headerbg", "color_7");
        break;
      case "color_8":
        body.attr("data-headerbg", "color_8");
        break;
      case "color_9":
        body.attr("data-headerbg", "color_9");
        break;
      case "color_10":
        body.attr("data-headerbg", "color_10");
        break;
      case "color_11":
        body.attr("data-headerbg", "color_11");
        break;
      case "color_12":
        body.attr("data-headerbg", "color_12");
        break;
      case "color_13":
        body.attr("data-headerbg", "color_13");
        break;
      case "color_14":
        body.attr("data-headerbg", "color_14");
        break;
      case "color_15":
        body.attr("data-headerbg", "color_15");
        break;
      case "transparent":
        body.attr("data-headerbg", "transparent");
        break;
      case "gradient_1":
        body.attr("data-headerbg", "gradient_1");
        break;
      case "gradient_2":
        body.attr("data-headerbg", "gradient_2");
        break;
      case "gradient_3":
        body.attr("data-headerbg", "gradient_3");
        break;
      default:
        body.attr("data-headerbg", "color_1");
    }
  };
  dezSettings.prototype.manageSidebarStyle = function () {
    switch (this.sidebarStyle) {
      case "full":
        body.attr("data-sidebar-style", "full");
        break;
      case "mini":
        body.attr("data-sidebar-style", "mini");
        break;
      case "compact":
        body.attr("data-sidebar-style", "compact");
        break;
      case "modern":
        body.attr("data-sidebar-style", "modern");
        break;
      case "icon-hover":
        body.attr("data-sidebar-style", "icon-hover");

        $(".deznav").hover(
          function () {
            $("#main-wrapper").addClass("icon-hover-toggle");
          },
          function () {
            $("#main-wrapper").removeClass("icon-hover-toggle");
          }
        );
        break;
      case "overlay":
        this.layout === "horizontal"
          ? body.attr("data-sidebar-style", "full")
          : body.attr("data-sidebar-style", "overlay");
        break;
      default:
        body.attr("data-sidebar-style", "full");
    }
  };
  dezSettings.prototype.manageSidebarBg = function () {
    switch (this.sidebarBg) {
      case "color_1":
        body.attr("data-sibebarbg", "color_1");
        break;
      case "color_2":
        body.attr("data-sibebarbg", "color_2");
        break;
      case "color_3":
        body.attr("data-sibebarbg", "color_3");
        break;
      case "color_4":
        body.attr("data-sibebarbg", "color_4");
        break;
      case "color_5":
        body.attr("data-sibebarbg", "color_5");
        break;
      case "color_6":
        body.attr("data-sibebarbg", "color_6");
        break;
      case "color_7":
        body.attr("data-sibebarbg", "color_7");
        break;
      case "color_8":
        body.attr("data-sibebarbg", "color_8");
        break;
      case "color_9":
        body.attr("data-sibebarbg", "color_9");
        break;
      case "color_10":
        body.attr("data-sibebarbg", "color_10");
        break;
      case "color_11":
        body.attr("data-sibebarbg", "color_11");
        break;
      case "color_12":
        body.attr("data-sibebarbg", "color_12");
        break;
      case "color_13":
        body.attr("data-sibebarbg", "color_13");
        break;
      case "color_14":
        body.attr("data-sibebarbg", "color_14");
        break;
      case "color_15":
        body.attr("data-sibebarbg", "color_15");
        break;
      case "image_1":
        body.attr("data-sibebarbg", "image_1");
        break;
      case "image_2":
        body.attr("data-sibebarbg", "image_2");
        break;
      case "image_3":
        body.attr("data-sibebarbg", "image_3");
        break;
      default:
        body.attr("data-sibebarbg", "color_1");
    }
  };
  dezSettings.prototype.manageSidebarPosition = function () {
    switch (this.sidebarPosition) {
      case "fixed":
        (this.sidebarStyle === "overlay" && this.layout === "vertical") ||
        this.sidebarStyle === "modern"
          ? body.attr("data-sidebar-position", "static")
          : body.attr("data-sidebar-position", "fixed");
        break;
      case "static":
        body.attr("data-sidebar-position", "static");
        break;
      default:
        body.attr("data-sidebar-position", "static");
    }
  };
  dezSettings.prototype.manageHeaderPosition = function () {
    switch (this.headerPosition) {
      case "fixed":
        body.attr("data-header-position", "fixed");
        break;
      case "static":
        body.attr("data-header-position", "static");
        break;
      default:
        body.attr("data-header-position", "static");
    }
  };
  dezSettings.prototype.manageContainerLayout = function () {
    switch (this.containerLayout) {
      case "boxed":
        if (this.layout === "vertical" && this.sidebarStyle === "full") {
          body.attr("data-sidebar-style", "overlay");
        }
        body.attr("data-container", "boxed");
        break;
      case "wide":
        body.attr("data-container", "wide");
        break;
      case "wide-boxed":
        body.attr("data-container", "wide-boxed");
        break;
      default:
        body.attr("data-container", "wide");
    }
  };
  dezSettings.prototype.manageRtlLayout = function () {
    //alert(this.direction);
    switch (this.direction) {
      case "rtl":
        html.attr("dir", "rtl");
        html.addClass("rtl");
        body.attr("direction", "rtl");
        break;
      case "ltr":
        html.attr("dir", "ltr");
        html.removeClass("rtl");
        body.attr("direction", "ltr");
        break;
      default:
        html.attr("dir", "ltr");
        body.attr("direction", "ltr");
    }
  };
  dezSettings.prototype.manageResponsiveSidebar = function () {
    const innerWidth = $(window).innerWidth();
    if (innerWidth < 1200) {
      body.attr("data-layout", "vertical");
      body.attr("data-container", "wide");
    }

    if (innerWidth > 767 && innerWidth < 1200) {
      body.attr("data-sidebar-style", "mini");
    }

    if (innerWidth < 768) {
      body.attr("data-sidebar-style", "overlay");
    }
  };
  dezSettings.prototype.managePrimaryColor = function () {
    switch (this.primary) {
      case "color_1":
        body.attr("data-primary", "color_1");
        break;
      case "color_2":
        body.attr("data-primary", "color_2");
        break;
      case "color_3":
        body.attr("data-primary", "color_3");
        break;
      case "color_4":
        body.attr("data-primary", "color_4");
        break;
      case "color_5":
        body.attr("data-primary", "color_5");
        break;
      case "color_6":
        body.attr("data-primary", "color_6");
        break;
      case "color_7":
        body.attr("data-primary", "color_7");
        break;
      case "color_8":
        body.attr("data-primary", "color_8");
        break;
      case "color_9":
        body.attr("data-primary", "color_9");
        break;
      case "color_10":
        body.attr("data-primary", "color_10");
        break;
      case "color_11":
        body.attr("data-primary", "color_11");
        break;
      case "color_12":
        body.attr("data-primary", "color_12");
        break;
      case "color_13":
        body.attr("data-primary", "color_13");
        break;
      case "color_14":
        body.attr("data-primary", "color_14");
        break;
      case "color_15":
        body.attr("data-primary", "color_15");
        break;

      default:
        body.attr("data-primary", "color_1");
    }
  };

  // /////////////////////////////////////////////////////////////////////
  // /////////////////////////////////////////////////////////////////////
  // /////////////////////////////////////////////////////////////////////
  // /////////////////////////////////////////////////////////////////////
  // /////////////////////////////////////////////////////////////////////
  // /////////////////////////////////////////////////////////////////////
  // /////////////////////////////////////////////////////////////////////
  // /////////////////////////////////////////////////////////////////////

  let prs = [];

  // $(function() {
  //     AOS.init({
  //         duration: 1500,
  //         easing: 'ease-in-out',
  //     });
  // });

  $("#checkAll, .checkAll").change(function () {
    $("td input:checkbox").prop("checked", $(this).prop("checked"));
  });

  /* $('.sidebar-right-inner').slimscroll({
        position: "left",
        size: "5px",
        height: "100%",
        color: "#c6c8c9"
    }); */

  $(".nav-control").on("click", function () {
    $("#main-wrapper").toggleClass("menu-toggle");

    $(".hamburger").toggleClass("is-active");
  });

  //to keep the current page active

  for (
    var nk = window.location,
      o = $("ul#menu a")
        .filter(function () {
          return this.href === nk;
        })
        .addClass("mm-active")
        .parent()
        .addClass("mm-active");
    ;

  ) {
    if (!o.is("li")) break;
    o = o.parent().addClass("mm-show").parent().addClass("mm-active");
  }

  $("ul#menu>li").on("click", function () {
    const sidebarStyle = $("body").attr("data-sidebar-style");
    if (sidebarStyle === "mini") {
      $(this).find("ul").stop();
    }
  });

  // //////////////////////////

  var win_h = window.outerHeight;
  if (win_h > 0 ? win_h : window.screen.height) {
    $(".content-body").css("min-height", win_h + 60 + "px");
  }

  $('a[data-action="collapse"]').on("click", function (i) {
    i.preventDefault();
    $(this)
      .closest(".card")
      .find('[data-action="collapse"] i')
      .toggleClass("mdi-arrow-down mdi-arrow-up");
    $(this).closest(".card").children(".card-body").collapse("toggle");
  });

  $('a[data-action="expand"]').on("click", function (i) {
    i.preventDefault();
    $(this)
      .closest(".card")
      .find('[data-action="expand"] i')
      .toggleClass("icon-size-actual icon-size-fullscreen");
    $(this).closest(".card").toggleClass("card-fullscreen");
  });

  $('[data-action="close"]').on("click", function () {
    $(this).closest(".card").removeClass().slideUp("fast");
  });

  $('[data-action="reload"]').on("click", function () {
    var e = $(this);
    e.parents(".card").addClass("card-load");
    e.parents(".card").append(
      '<div className="card-loader"><i className=" ti-reload rotate-refresh"></div>'
    );
    setTimeout(function () {
      e.parents(".card").children(".card-loader").remove();
      e.parents(".card").removeClass("card-load");
    }, 2000);
  });

  const headerHight = $(".header").innerHeight();

  $(window).scroll(function () {
    if (
      $("body").attr("data-layout") === "horizontal" &&
      $("body").attr("data-header-position") === "static" &&
      $("body").attr("data-sidebar-position") === "fixed"
    )
      $(this.window).scrollTop() >= headerHight
        ? $(".deznav").addClass("fixed")
        : $(".deznav").removeClass("fixed");
  });

  jQuery(".dz-scroll").each(function () {
    var scroolWidgetId = jQuery(this).attr("id");
    const newPr = new PerfectScrollbar("#" + scroolWidgetId, {
      wheelSpeed: 2,
      wheelPropagation: true,
      minScrollbarLength: 20,
    });

    prs.push(newPr);
  });

  jQuery(".metismenu > .mm-active ").each(function () {
    if (!jQuery(this).children("ul").length > 0) {
      jQuery(this).addClass("active-no-child");
    }
  });
  if (screenWidth <= 991) {
    jQuery(".menu-tabs .nav-link").on("click", function () {
      if (jQuery(this).hasClass("open")) {
        jQuery(this).removeClass("open");
        jQuery(".fixed-content-box").removeClass("active");
        jQuery(".hamburger").show();
      } else {
        jQuery(".menu-tabs .nav-link").removeClass("open");
        jQuery(this).addClass("open");
        jQuery(".fixed-content-box").addClass("active");
        jQuery(".hamburger").hide();
      }
      //jQuery('.fixed-content-box').toggleClass('active');
    });
    jQuery(".close-fixed-content").on("click", function () {
      jQuery(".fixed-content-box").removeClass("active");
      jQuery(".hamburger").removeClass("is-active");
      jQuery("#main-wrapper").removeClass("menu-toggle");
      jQuery(".hamburger").show();
    });
  }
  jQuery(".bell-link").on("click", function () {
    jQuery(".chatbox").addClass("active");
  });
  jQuery(".chatbox-close").on("click", function () {
    jQuery(".chatbox").removeClass("active");
  });

  if ($(".deznav-scroll").length > 0) {
    const newPr = new PerfectScrollbar(".deznav-scroll");
    prs.push(newPr);
  }

  // const sr = new PerfectScrollbar('.sidebar-right-inner');

  //plugin bootstrap minus and plus
  $(".btn-number").on("click", function (e) {
    e.preventDefault();

    let fieldName = $(this).attr("data-field");
    let type = $(this).attr("data-type");
    var input = $("input[name='" + fieldName + "']");
    var currentVal = parseInt(input.val());
    if (!isNaN(currentVal)) {
      if (type === "minus") input.val(currentVal - 1);
      else if (type === "plus") input.val(currentVal + 1);
    } else {
      input.val(0);
    }
  });

  jQuery(".dz-chat-user-box .dz-chat-user").on("click", function () {
    jQuery(".dz-chat-user-box").addClass("d-none");
    jQuery(".dz-chat-history-box").removeClass("d-none");
  });

  jQuery(".dz-chat-history-back").on("click", function () {
    jQuery(".dz-chat-user-box").removeClass("d-none");
    jQuery(".dz-chat-history-box").addClass("d-none");
  });

  jQuery(".dz-fullscreen").on("click", function () {
    jQuery(".dz-fullscreen").toggleClass("active");
  });

  jQuery(".schedule-event-inner").on("click", function () {
    jQuery(".event-sidebar").toggleClass("active");
  });

  // $("#datetimepicker1").datetimepicker({
  //   inline: true,
  // });

  jQuery(".dz-fullscreen").on("click", function (e) {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      /* Enter fullscreen */

      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen(); /* IE/Edge */
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen(); /* Firefox */
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen(); /* Chrome, Safari & Opera */
      }
    } else {
      /* exit fullscreen */
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      }
    }
  });

  return prs;

  // Chart All Pages
}
