import React, { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";

import BreadCrumb from "../../components/UI/Breadcrumb";
import FormGroup from "../../components/FormElements/FormGroup";
import Input from "../../components/FormElements/Input";
import ChangeTitle from "../../components/UI/ChangeTitle";
import FormButton from "../../components/Buttons/FormButton";
import AlertDismissible from "../../components/Alert/AlertDismissible";
import Loader from "../../components/UI/Loader";
import Select from "../../components/FormElements/Select";
import isEmpty from "../../utils/is-empty";

const initialState = { task: "", description: "" };

const AddUpdateTask = ({ mode }) => {
  const projectFormGroupRef = useRef(null);
  const scopeFormGroupRef = useRef(null);
  const { taskId, projectId, sowId } = useParams();
  const history = useHistory();
  const [projectsState, setProjectsState] = useState([]);
  const [sowsState, setSowsState] = useState([]);
  const [modulesState, setModulesState] = useState([]);
  const [subModulesState, setSubModulesState] = useState([]);
  const [formState, setFormState] = useState(initialState);
  const [isLoading, setIsLoading] = useState(true);
  const [isBeingSubmitted, setIsBeingSubmitted] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [errorState, setErrorState] = useState("");

  let title = `${mode === "edit" ? "Update a" : "Add"} task ${
    mode === "edit" ? "" : "(Step: 1/2)"
  }`;

  const inputChangeHandler = (e, name, value) => {
    if (e) {
      ({ name, value } = e.target);
    }

    if (name === "moduleId") {
      console.log("module changed");
    }

    setFormState((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const formSubmitHandler = async (e) => {
    setIsBeingSubmitted(true);
    setResponseMessage(false);
    setErrorState(false);
    e.preventDefault();

    let res;
    if (mode === "edit") {
      try {
        res = await axios.patch(`/tasks/${taskId}`, formState);
      } catch (err) {
        console.log(err);
        if (err.response) {
          setErrorState(err.response.data.errors[0]);
        }
      }

      setResponseMessage(res.data.msg);
      setIsBeingSubmitted(false);
    } else {
      const { projectId, sowId, moduleId, subModuleId } = formState;
      history.push(
        `/add-task/${projectId}/${sowId}/${moduleId}/${subModuleId || ""}`
      );
    }
  };

  useEffect(() => {
    if (!isEmpty(projectId) && !isEmpty(sowId)) {
      setFormState((prevState) => {
        return { ...prevState, projectId, sowId };
      });

      projectFormGroupRef.current.hidden = true;
      scopeFormGroupRef.current.hidden = true;
    }
  }, [projectId, sowId]);

  useEffect(() => {
    const fetchMyAPI = async (mode) => {
      setIsLoading(true);
      try {
        if (mode === "edit") {
          const res = await axios.get(`/tasks/${taskId}`);
          const { task } = res.data;
          setFormState(task);
        } else {
          const res = await axios.get(`/tasks/associated-fields`);
          const { projects, sows, modules, subModules } = res.data;
          setProjectsState(projects);
          setSowsState(sows);
          setModulesState(modules);
          setSubModulesState(subModules);
        }

        setIsLoading(false);
      } catch (err) {
        console.log(err);
        if (err.response) {
          console.log(err.response.data);
          return setErrorState(err.response.data.errors[0]);
        }
        setErrorState(
          `Failed to fetch ${mode === "edit" ? "module" : "scope of work"}`
        );
      }
    };

    fetchMyAPI(mode);
  }, [taskId, mode]);

  return (
    <>
      <ChangeTitle
        title={title}
        buttonTitle="View tasks"
        buttonPath={`/tasks`}
        buttonSmallIcon="list"
      />
      <BreadCrumb
        showTaskName={mode === "edit"}
        items={[{ title: "Tasks", path: "/tasks" }, title]}
      />
      <AlertDismissible>{responseMessage}</AlertDismissible>
      <AlertDismissible onClose={() => setErrorState(false)} danger>
        {errorState}
      </AlertDismissible>

      {mode === "edit" && isLoading ? (
        <Loader />
      ) : (
        <form onSubmit={formSubmitHandler}>
          {mode !== "edit" && (
            <>
              <FormGroup label="Project *" innerRef={projectFormGroupRef}>
                <Select
                  firstEmpty
                  name="projectId"
                  value={formState.projectId}
                  onChange={inputChangeHandler}
                  options={projectsState.map((el) => {
                    return { text: el.name, value: el.id };
                  })}
                />
              </FormGroup>

              <FormGroup label="Scope of work *" innerRef={scopeFormGroupRef}>
                <Select
                  firstEmpty
                  name="sowId"
                  value={formState.sowId}
                  onChange={inputChangeHandler}
                  options={sowsState
                    .filter(
                      (el) =>
                        parseInt(el.projectId) === parseInt(formState.projectId)
                    )
                    .map((el) => {
                      return { text: el.name, value: el.id };
                    })}
                />
              </FormGroup>

              <FormGroup label="Module *">
                <Select
                  firstEmpty
                  name="moduleId"
                  value={formState.moduleId}
                  onChange={inputChangeHandler}
                  options={modulesState
                    .filter(
                      (el) => parseInt(el.sowId) === parseInt(formState.sowId)
                    )
                    .map((el) => {
                      return { text: el.name, value: el.id };
                    })}
                />
              </FormGroup>

              <FormGroup label="Sub module *">
                <Select
                  firstEmpty
                  name="subModuleId"
                  value={formState.subModuleId}
                  onChange={inputChangeHandler}
                  options={subModulesState
                    .filter(
                      (el) =>
                        parseInt(el.moduleId) === parseInt(formState.moduleId)
                    )
                    .map((el) => {
                      return { text: el.name, value: el.id };
                    })}
                />
              </FormGroup>
            </>
          )}

          {mode === "edit" && (
            <>
              <FormGroup label="Task *">
                <Input
                  type="text"
                  name="task"
                  placeholder="Task"
                  value={formState.task || ""}
                  onChange={inputChangeHandler}
                />
              </FormGroup>

              <FormGroup label="Description *">
                <Input
                  textarea
                  type="text"
                  name="description"
                  placeholder="Description"
                  value={formState.description || ""}
                  onChange={inputChangeHandler}
                />
              </FormGroup>
            </>
          )}

          <FormGroup>
            <FormButton
              disabled={
                isBeingSubmitted ||
                !formState.projectId ||
                !formState.sowId ||
                !formState.moduleId
              }
            >
              {mode === "edit" ? "Update" : "Proceed"}
            </FormButton>
          </FormGroup>
        </form>
      )}
    </>
  );
};

export default AddUpdateTask;
