import React, { useState } from "react";
import { Alert } from "react-bootstrap";

const AlertDismissible = (props) => {
  const { onClose, danger, children } = props;
  const [show, setShow] = useState(true);

  if (!children) {
    return <div></div>;
  }

  return (
    show &&
    children && (
      <Alert
        dismissible
        onClose={() => {
          if (onClose) {
            onClose();
          } else {
            setShow(false);
          }
        }}
        variant={danger ? "danger" : "success"}
      >
        {children}
      </Alert>
    )
  );
};

export default AlertDismissible;
