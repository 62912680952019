import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const UpdateParamNameAndGo = (props) => {
  const {
    paramSetter,
    paramName,
    paramSetters,
    paramNames,
    title,
    path,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  const paramNameChangeHandler = () => {
    if (paramSetter) {
      dispatch(paramSetter(paramName));
    } else if (paramSetters.length !== 0) {
      paramSetters.forEach((setter, idx) => {
        const paramName = paramNames[idx];
        dispatch(setter(paramName));
      });
    }
    history.push(path);
  };

  return (
    <button
      onClick={() => {
        paramNameChangeHandler();
      }}
      className="dropdown-item"
    >
      {title}
    </button>
  );
};

export default UpdateParamNameAndGo;
