import React from "react";

const MappedErrors = (props) => {
  const { errors, small } = props;

  return (
    <>
      {errors.map((error, idx) => {
        return (
          <div
            key={idx}
            className={`alert alert-danger ${small ? "small" : ""}`}
          >
            {error}
          </div>
        );
      })}
    </>
  );
};

export default MappedErrors;
