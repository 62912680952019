const isImageFile = (src) => {
  src = src.toLowerCase();
  return (
    src.endsWith(".tiff") ||
    src.endsWith(".pjp") ||
    src.endsWith(".jfif") ||
    src.endsWith(".bmp") ||
    src.endsWith(".gif") ||
    src.endsWith(".svg") ||
    src.endsWith(".png") ||
    src.endsWith(".xbm") ||
    src.endsWith(".dib") ||
    src.endsWith(".jxl") ||
    src.endsWith(".jpeg") ||
    src.endsWith(".svgz") ||
    src.endsWith(".jpg") ||
    src.endsWith(".webp") ||
    src.endsWith(".ico") ||
    src.endsWith(".tif") ||
    src.endsWith(".pjpeg") ||
    src.endsWith(".avif")
  );
};

export default isImageFile;
