import React, { Fragment, useEffect, useRef } from "react";
import $ from "jquery";

import H4 from "../../components/Headings/H4";

$.DataTable = require("datatables.net");

const DataTable = ({
  heading,
  titles = [],
  children,
  style,
  paging = true,
  ordering = true,
  serialNo = false,
  ...rest
}) => {
  const table = useRef(null);
  const tableContainer = useRef(null);

  useEffect(() => {
    if (!$.fn.dataTable.isDataTable(table.current)) {
      if (children) {
        $(table.current).DataTable({ paging, ordering });
      }
    }
  }, [table, children, paging, ordering]);

  return (
    <Fragment>
      <div className="table-responsive" style={{ paddingBottom: "13rem" }}>
        {heading && <H4>{heading}</H4>}
        <div ref={tableContainer}></div>
        <table
          {...rest}
          ref={table}
          id=""
          className="display"
          style={{ minWidth: "845px" }}
        >
          {children ? (
            <Fragment>
              <thead>
                <tr>
                  {serialNo && <th style={{ width: "80px" }}>#</th>}
                  {titles.map((title) => {
                    return (
                      <th key={Math.random()}>
                        <strong>{title}</strong>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>{children}</tbody>
            </Fragment>
          ) : (
            <tbody>
              <tr
                style={{
                  textAlign: "center",
                }}
              >
                <td className="p-3">No records to show</td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
    </Fragment>
  );
};

// export default React.memo(DataTable, () => true);
export default DataTable;
