import updateObject from "../../utils/update-object";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
  currentModuleName: "",
  currentSubModuleName: "",
  currentProjectName: "",
  currentSowName: "",
  currentTaskName: "",
};

const setCurrentModule = (state, action) => {
  return updateObject(state, { currentModuleName: action.moduleName });
};

const setCurrentSubModule = (state, action) => {
  return updateObject(state, { currentSubModuleName: action.subModuleName });
};

const setCurrentProject = (state, action) => {
  return updateObject(state, { currentProjectName: action.projectName });
};

const setCurrentSow = (state, action) => {
  return updateObject(state, { currentSowName: action.sowName });
};

const setCurrentTask = (state, action) => {
  return updateObject(state, { currentTaskName: action.taskName });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_MODULE:
      return setCurrentModule(state, action);
    case actionTypes.SET_CURRENT_SUB_MODULE:
      return setCurrentSubModule(state, action);
    case actionTypes.SET_CURRENT_PROJECT:
      return setCurrentProject(state, action);
    case actionTypes.SET_CURRENT_SOW:
      return setCurrentSow(state, action);
    case actionTypes.SET_CURRENT_TASK:
      return setCurrentTask(state, action);
    default:
      return state;
  }
};

export default reducer;
