import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router";

import BreadCrumb from "../../../components/UI/Breadcrumb";
import Loader from "../../../components/UI/Loader";
import DataTable from "../../../components/Tables/DataTable";
import TableActions from "../../../components/Buttons/TableActions";
import ChangeTitle from "../../../components/UI/ChangeTitle";
import AlertDismissible from "../../../components/Alert/AlertDismissible";
import DropdownConfirmDeleteBtn from "../../../components/UI/DropdownConfirmDeleteBtn";
// import { setCurrentTask } from "../../../store/actions/paramsActions";
// import UpdateParamNameAndGo from "../../../components/Buttons/UpdateParamNameAndGo";

const TaskStatuses = () => {
  const { taskId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [tasksState, setTasksState] = useState([]);
  const [responseMessage, setResponseMessage] = useState();
  const [errorState, setErrorState] = useState();

  const fetchTaskStatuses = useCallback(() => {
    setIsLoading(true);
    axios
      .get(`/tasks/${taskId}`)
      .then((res) => {
        setTasksState(res.data.task.taskStatuses);
      })
      .catch((err) => {
        if (err.response) {
          setErrorState(err.response.data.errors[0]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [taskId]);

  const deleteTaskHandler = (statuId) => {
    setErrorState(false);
    setResponseMessage(false);
    axios
      .delete(`/tasks/statuses/${statuId}`)
      .then((res) => {
        setIsLoading(true);
        setResponseMessage(res.data.msg);
        fetchTaskStatuses();
      })
      .catch((err) => {
        if (err.response) {
          setErrorState(err.response.data.errors[0]);
        }
      });
  };

  useEffect(() => {
    fetchTaskStatuses();
  }, [fetchTaskStatuses]);

  return (
    <>
      <ChangeTitle
        title="Task statuses"
        buttonTitle="Add a status"
        buttonPath={`/add-task-status/${taskId}`}
        buttonSmallIcon="plus"
      />
      <BreadCrumb
        items={[{ title: "Tasks", path: "/tasks" }, "Task statuses"]}
      />

      {isLoading ? (
        <Loader />
      ) : (
        <>
          <AlertDismissible>{responseMessage}</AlertDismissible>
          <AlertDismissible onClose={() => setErrorState(false)} danger>
            {errorState}
          </AlertDismissible>
          <DataTable titles={["Status", "Actions"]}>
            {tasksState.map((el) => {
              return (
                <tr key={el.id}>
                  <td>{el.status}</td>
                  <td>
                    <TableActions>
                      <DropdownConfirmDeleteBtn
                        deleteConfirmed={() => deleteTaskHandler(el.id)}
                      />
                    </TableActions>
                  </td>
                </tr>
              );
            })}
          </DataTable>
        </>
      )}
    </>
  );
};

export default TaskStatuses;
