import React from "react";

const Input = (props) => {
  const { name, type, textarea, className, ...rest } = props;

  if (textarea) {
    return (
      <textarea
        rows={5}
        name={name}
        type={type}
        className="form-control"
        {...rest}
      ></textarea>
    );
  } else {
  }
  return (
    <>
      <input
        type={type}
        id={name}
        name={name}
        className={`form-control ${className ? className : ""} ${
          type === "checkbox" ? "custom-control-input" : ""
        }`}
        {...rest}
      />
      {type === "checkbox" && (
        <label className="custom-control-label" htmlFor={name}></label>
      )}
    </>
  );
};

export default Input;
