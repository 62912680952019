import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import changeTitle from "../../utils/change-title";

const ChangeTitle = (props) => {
  const { title, buttonTitle, buttonPath, buttonSmallIcon } = props;
  const history = useHistory();

  useEffect(() => {
    if (buttonPath && buttonTitle) {
      changeTitle(
        title,
        { title: buttonTitle, path: buttonPath, smallIcon: buttonSmallIcon },
        history
      );
    } else {
      changeTitle(title);
    }
  }, [title, buttonTitle, buttonPath, history, buttonSmallIcon]);

  return <div></div>;
};

export default ChangeTitle;
