import React from "react";

const FormButton = (props) => {
  const { children: title, noOutline, className, ...rest } = props;

  return (
    <button
      {...rest}
      type="submit"
      className={`btn ${
        noOutline ? "btn-primary btn-block" : "btn-outline-primary"
      } mt-3 ${className}`}
    >
      {title}
    </button>
  );
};

export default FormButton;
