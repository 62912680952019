import React, { useEffect, useState } from "react";
import axios from "axios";

import Loader from "../../components/UI/Loader";
import DataTable from "../../components/Tables/DataTable";
import TableActions from "../../components/Buttons/TableActions";
import ChangeTitle from "../../components/UI/ChangeTitle";
import AlertDismissible from "../../components/Alert/AlertDismissible";
import DropdownConfirmDeleteBtn from "../../components/UI/DropdownConfirmDeleteBtn";
import { setCurrentSeller } from "../../store/actions/paramsActions";
import UpdateParamNameAndGo from "../../components/Buttons/UpdateParamNameAndGo";

const Sellers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [sellersState, setSellersState] = useState([]);
  const [responseMessage, setResponseMessage] = useState();
  const [errorState, setErrorState] = useState();

  const fetchSellers = () => {
    setIsLoading(true);
    axios
      .get("/sellers")
      .then((res) => {
        setSellersState(res.data.sellers);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const deleteSellerHandler = (sellerId) => {
    setErrorState(false);
    setResponseMessage(false);
    axios
      .delete(`/sellers/${sellerId}`)
      .then((res) => {
        setIsLoading(true);
        setResponseMessage(res.data.msg);
        fetchSellers();
      })
      .catch((err) => {
        if (err.response) {
          setErrorState(err.response.data.errors[0]);
        }
      });
  };

  useEffect(() => {
    fetchSellers();
  }, []);

  return (
    <>
      <ChangeTitle
        title="Sellers"
        buttonTitle="Add a seller"
        buttonPath="/add-seller"
        buttonSmallIcon="plus"
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <AlertDismissible>{responseMessage}</AlertDismissible>
          <AlertDismissible onClose={() => setErrorState(false)} danger>
            {errorState}
          </AlertDismissible>
          <DataTable titles={["Name", "Email", "Website", "Phone", "Actions"]}>
            {sellersState.map((el) => {
              return (
                <tr key={el._id}>
                  <td>{el.name}</td>
                  <td>{el.email}</td>
                  <td>{el.website}</td>
                  <td>{el.phone}</td>
                  <td>
                    <TableActions>
                      <UpdateParamNameAndGo
                        paramSetter={setCurrentSeller}
                        title="View services"
                        paramName={el.name}
                        path={`/services/${el._id}`}
                      />
                      <UpdateParamNameAndGo
                        paramSetter={setCurrentSeller}
                        title="Add service"
                        paramName={el.name}
                        path={`/add-service/${el._id}`}
                      />
                      <UpdateParamNameAndGo
                        paramSetter={setCurrentSeller}
                        title="Update image"
                        paramName={el.name}
                        path={`/update-seller-image/${el._id}`}
                      />
                      <UpdateParamNameAndGo
                        paramSetter={setCurrentSeller}
                        title="Update"
                        paramName={el.name}
                        path={`/update-seller/${el._id}`}
                      />
                      {el.services.length === 0 && (
                        <DropdownConfirmDeleteBtn
                          deleteConfirmed={() => deleteSellerHandler(el._id)}
                        />
                      )}
                    </TableActions>
                  </td>
                </tr>
              );
            })}
          </DataTable>
        </>
      )}
    </>
  );
};

export default Sellers;
