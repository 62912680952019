import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";

import "./shared/js/custom.min.js";
import "./shared/js/deznav-init.js";
import Main from "./Layout/Main";
import Home from "./containers/Home";
import RouteWithLayout from "./containers/RouteWithLayout";
import Signin from "./containers/Auth/Signin";
// import Signup from "./containers/Auth/Signup";
import Sellers from "./containers/Sellers/Sellers";
import AddSeller from "./containers/Sellers/AddSeller";
import UpdateSellerImage from "./containers/Sellers/UpdateSellerImage";
import UpdateSeller from "./containers/Sellers/UpdateSeller";

import Projects from "./containers/Projects/Projects";
import AddUpdateProject from "./containers/Projects/AddUpdateProject";

import SOWs from "./containers/SOWs/SOWs";
import AddUpdateSOW from "./containers/SOWs/AddUpdateSOW";
import StatusLinks from "./containers/SOWs/StatusLinks/StatusLinks";

import Modules from "./containers/Modules/Modules";
import AddUpdateModules from "./containers/Modules/AddUpdateModule";

import SubModules from "./containers/SubModules/SubModules";
import AddUpdateSubModule from "./containers/SubModules/AddUpdateSubModule";

import Tasks from "./containers/Tasks/Tasks";
import AddUpdateTask from "./containers/Tasks/AddUpdateTask";
import AddTaskFinal from "./containers/Tasks/AddTaskFinal";
import ViewTaskAttachments from "./containers/Tasks/ViewTaskAttachments.js";
import AddTaskAttachment from "./containers/Tasks/AddTaskAttachment";
import TaskStatuses from "./containers/Tasks/TaskStatuses/TaskStatuses";
import AddTaskStatus from "./containers/Tasks/TaskStatuses/AddTaskStatus.js";

const Routes = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  if (isAuthenticated) {
    return (
      <Router>
        <Switch>
          <RouteWithLayout exact path="/" component={Home} layout={Main} />
          <RouteWithLayout
            exact
            path="/sellers"
            component={Sellers}
            layout={Main}
          />
          <RouteWithLayout
            exact
            path="/add-seller"
            component={AddSeller}
            layout={Main}
          />
          <RouteWithLayout
            exact
            path="/update-seller/:sellerId"
            component={UpdateSeller}
            layout={Main}
          />
          <RouteWithLayout
            exact
            path="/update-seller-image/:sellerId"
            component={UpdateSellerImage}
            layout={Main}
          />

          <RouteWithLayout
            exact
            path="/projects"
            component={Projects}
            layout={Main}
          />
          <RouteWithLayout
            exact
            path="/add-project"
            component={AddUpdateProject}
            layout={Main}
          />
          <RouteWithLayout
            compProps={{ mode: "edit" }}
            exact
            path="/update-project/:projectId"
            component={AddUpdateProject}
            layout={Main}
          />

          <RouteWithLayout
            exact
            path="/sows/:projectId?"
            component={SOWs}
            layout={Main}
          />
          <RouteWithLayout
            exact
            path="/add-sow"
            component={AddUpdateSOW}
            layout={Main}
          />
          <RouteWithLayout
            exact
            path="/status-links/:taskCryptoId"
            component={StatusLinks}
            layout={Main}
          />
          <RouteWithLayout
            compProps={{ mode: "edit" }}
            exact
            path="/update-sow/:sowId"
            component={AddUpdateSOW}
            layout={Main}
          />

          <RouteWithLayout
            exact
            path="/modules/:sowId?"
            component={Modules}
            layout={Main}
          />
          <RouteWithLayout
            exact
            path="/add-module"
            component={AddUpdateModules}
            layout={Main}
          />
          <RouteWithLayout
            compProps={{ mode: "edit" }}
            exact
            path="/update-module/:moduleId"
            component={AddUpdateModules}
            layout={Main}
          />

          <RouteWithLayout
            exact
            path="/sub-modules"
            component={SubModules}
            layout={Main}
          />
          <RouteWithLayout
            exact
            path="/add-sub-module"
            component={AddUpdateSubModule}
            layout={Main}
          />
          <RouteWithLayout
            compProps={{ mode: "edit" }}
            exact
            path="/update-sub-module/:subModuleId"
            component={AddUpdateSubModule}
            layout={Main}
          />

          <RouteWithLayout
            exact
            path="/tasks"
            component={Tasks}
            layout={Main}
          />
          <RouteWithLayout
            exact
            path="/add-task/:projectId?/:sowId?"
            component={AddUpdateTask}
            layout={Main}
          />
          <RouteWithLayout
            exact
            path="/add-task/:projectId/:sowId/:moduleId/:subModuleId?"
            component={AddTaskFinal}
            layout={Main}
          />
          <RouteWithLayout
            exact
            path="/view-task-attachments/:taskId"
            component={ViewTaskAttachments}
            layout={Main}
          />
          <RouteWithLayout
            exact
            path="/add-task-attachment/:taskId"
            component={AddTaskAttachment}
            layout={Main}
          />
          <RouteWithLayout
            compProps={{ mode: "edit" }}
            exact
            path="/update-task/:taskId"
            component={AddUpdateTask}
            layout={Main}
          />
          <RouteWithLayout
            exact
            path="/task-statuses/:taskId"
            component={TaskStatuses}
            layout={Main}
          />
          <RouteWithLayout
            exact
            path="/add-task-status/:taskId"
            component={AddTaskStatus}
            layout={Main}
          />
          <Redirect to="/" />
        </Switch>
      </Router>
    );
  } else {
    return (
      <Router>
        <Switch>
          {/* <Route exact path="/signup" component={Signup} /> */}
          <Route exact path="/signin" component={Signin} />
          <Redirect to="/signin" />
        </Switch>
      </Router>
    );
  }
};

export default Routes;
