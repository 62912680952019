import React, { useState } from "react";
import axios from "axios";

import BreadCrumb from "../../components/UI/Breadcrumb";
import FormGroup from "../../components/FormElements/FormGroup";
import Input from "../../components/FormElements/Input";
import ChangeTitle from "../../components/UI/ChangeTitle";
import FormButton from "../../components/Buttons/FormButton";
import AlertDismissible from "../../components/Alert/AlertDismissible";

const AddSeller = () => {
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    phone: "",
    website: "",
    facebook: "",
    instagram: "",
    youtube: "",
    twitter: "",
    linkedIn: "",
    isNameHidden: false,
    isEmailHidden: false,
    isPhoneHidden: false,
    isWebsiteHidden: false,
  });
  const [isBeingSubmitted, setIsBeingSubmitted] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [errorState, setErrorState] = useState("");

  const inputChangeHandler = (e, name, value) => {
    if (e) {
      ({ name, value } = e.target);
    }

    setFormState((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const formSubmitHandler = (e) => {
    setIsBeingSubmitted(true);
    setResponseMessage(false);
    setErrorState(false);
    e.preventDefault();

    axios
      .post("/sellers", formState)
      .then((res) => {
        setResponseMessage(res.data.msg);
        setFormState({
          name: "",
          email: "",
          website: "",
          phone: "",
          facebook: "",
          instagram: "",
          youtube: "",
          twitter: "",
          linkedIn: "",
          "video-text": "",
          "video-link": "",
          "pdf-text": "",
          "pdf-link": "",
        });
      })
      .catch((err) => {
        if (err.response) {
          setErrorState(err.response.data.errors[0]);
        }
      })
      .finally(() => {
        setIsBeingSubmitted(false);
      });
  };

  return (
    <>
      <ChangeTitle
        title="Add a seller"
        buttonTitle="View sellers"
        buttonPath="/sellers"
        buttonSmallIcon="list"
      />
      <BreadCrumb
        items={[{ title: "Sellers", path: "/sellers" }, "Add a seller"]}
      />
      <AlertDismissible>{responseMessage}</AlertDismissible>
      <AlertDismissible onClose={() => setErrorState(false)} danger>
        {errorState}
      </AlertDismissible>

      <form onSubmit={formSubmitHandler}>
        <FormGroup
          label="Name *"
          withShowHideToggler
          togglerPropName={"isNameHidden"}
          onToggle={inputChangeHandler}
          togglerInitialState={formState.isNameHidden}
        >
          <Input
            type="text"
            name="name"
            value={formState.name}
            onChange={inputChangeHandler}
            placeholder="John Doe"
          />
        </FormGroup>
        <FormGroup
          label="Email *"
          withShowHideToggler
          togglerPropName={"isEmailHidden"}
          onToggle={inputChangeHandler}
          togglerInitialState={formState.isEmailHidden}
        >
          <Input
            type="text"
            name="email"
            value={formState.email}
            onChange={inputChangeHandler}
            placeholder="johndoe@hello.com"
          />
        </FormGroup>
        <FormGroup
          label="Phone *"
          withShowHideToggler
          togglerPropName={"isPhoneHidden"}
          onToggle={inputChangeHandler}
          togglerInitialState={formState.isPhoneHidden}
        >
          <Input
            type="text"
            name="phone"
            value={formState.phone}
            onChange={inputChangeHandler}
            placeholder="Phone"
          />
        </FormGroup>
        <FormGroup
          label="Website"
          withShowHideToggler
          togglerPropName={"isWebsiteHidden"}
          onToggle={inputChangeHandler}
          togglerInitialState={formState.isWebsiteHidden}
        >
          <Input
            type="text"
            name="website"
            value={formState.website}
            onChange={inputChangeHandler}
            placeholder="https://www.hello.com"
          />
        </FormGroup>
        <FormGroup label="Facebook">
          <Input
            type="text"
            name="facebook"
            value={formState.facebook}
            onChange={inputChangeHandler}
            placeholder="Facebook"
          />
        </FormGroup>
        <FormGroup label="Instagram">
          <Input
            type="text"
            name="instagram"
            value={formState.instagram}
            onChange={inputChangeHandler}
            placeholder="Instagram"
          />
        </FormGroup>
        <FormGroup label="Youtube">
          <Input
            type="text"
            name="youtube"
            value={formState.youtube}
            onChange={inputChangeHandler}
            placeholder="Youtube"
          />
        </FormGroup>
        <FormGroup label="Twitter">
          <Input
            type="text"
            name="twitter"
            value={formState.twitter}
            onChange={inputChangeHandler}
            placeholder="Twitter"
          />
        </FormGroup>
        <FormGroup label="Linked In">
          <Input
            type="text"
            name="linkedIn"
            value={formState.linkedIn}
            onChange={inputChangeHandler}
            placeholder="Linked In"
          />
        </FormGroup>

        <FormGroup>
          <FormButton disabled={isBeingSubmitted}>Add</FormButton>
        </FormGroup>
      </form>
    </>
  );
};

export default AddSeller;
