import React, { useEffect } from "react";
import changeTitle from "../utils/change-title";

const Home = () => {
  useEffect(() => {
    changeTitle("Home");
  });

  return (
    <div>
      <h1 className="text-center">Hello</h1>
    </div>
  );
};

export default Home;
