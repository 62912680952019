import React from "react";

const FormGroup = (props) => {
  const {
    cols,
    label,
    fullWidth,
    children,
    rootClassName = "",
    withShowHideToggler,
    togglerInitialState: isTogglerHidden,
    onToggle,
    togglerPropName,
    checkbox,
    innerRef,
  } = props;

  const onShowToggle = (e) => {
    if (onToggle) onToggle(false, togglerPropName, !isTogglerHidden);
  };

  if (fullWidth) {
    return (
      <div ref={innerRef} className="form-group align-items-center">
        <label className="mb-1">
          <strong>{label}</strong>
        </label>
        {children}
      </div>
    );
  } else {
    return (
      <div ref={innerRef} className={`form-group row mx-auto ${rootClassName}`}>
        {label && (
          <label className="col-sm-3 col-form-label font-weight-500">
            {label}
          </label>
        )}
        <div
          className={`${cols ? cols : "col-sm-8 col-lg-5 col-md-6 col-10"} ${
            label ? "text-center" : ""
          }`}
        >
          <div className="row">
            <div
              className={`col-xl-12 ${
                checkbox ? "custom-control custom-checkbox" : ""
              }`}
            >
              {children}
            </div>
          </div>
        </div>
        {withShowHideToggler && (
          <div className="col-1">
            <img
              onClick={onShowToggle}
              className="ml-sm-3"
              src={isTogglerHidden ? "/icons/hide.png" : "/icons/show.png"}
              style={{ width: "1.75rem", cursor: "pointer" }}
              alt="show-hide-icon"
            />
          </div>
        )}
      </div>
    );
  }
};

export default FormGroup;
