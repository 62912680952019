import React, { useState } from "react";
import { useParams } from "react-router-dom";

import BreadCrumb from "../../../components/UI/Breadcrumb";
import FormGroup from "../../../components/FormElements/FormGroup";
import ChangeTitle from "../../../components/UI/ChangeTitle";
import FormButton from "../../../components/Buttons/FormButton";
import Select from "../../../components/FormElements/Select";
import { BitlyClient } from "bitly-react";
import copy from "copy-to-clipboard";
import { ToastContainer, toast } from "react-toastify";

import Constants from "../../../constants/constants";
import toKebabCase from "../../../utils/to-kebab-case";

const bitly = new BitlyClient("58f98003d6bd31c042985029405089a39c79d6c9", {});

const initialState = { status: "" };

const AddUpdateSOW = () => {
  const { taskCryptoId } = useParams();
  const [formState, setFormState] = useState(initialState);
  const [isBeingSubmitted, setIsBeingSubmitted] = useState(false);

  let title = "Copy link";

  const inputChangeHandler = (e, name, value) => {
    if (e) {
      ({ name, value } = e.target);
    }

    setFormState((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();
    setIsBeingSubmitted(true);
    let result;

    try {
      result = await bitly.shorten(
        `${Constants.defaultFrontEndHost}status-filter.html?status=${
          formState.status
        }&id=${taskCryptoId || ""}`
      );
    } catch (e) {
      console.log(e);
      toast.error("Please try again!", {
        position: "top-left",
        autoClose: 2000,
      });
    }

    setIsBeingSubmitted(false);
    copy(result.url);
    toast.success("Link copied to clipboard!", {
      position: "top-left",
      autoClose: 2000,
    });
  };

  return (
    <>
      <ToastContainer />
      <ChangeTitle
        title={title}
        buttonTitle="View scope of works"
        buttonPath={`/sows`}
        buttonSmallIcon="list"
      />
      <BreadCrumb
        showSOWName
        items={[{ title: "Scope of works", path: "/sows" }, title]}
      />

      <form onSubmit={formSubmitHandler}>
        <FormGroup label="Status *">
          <Select
            firstEmpty
            name="status"
            value={formState.status}
            onChange={inputChangeHandler}
            options={Constants.validStatuses.map((el) => {
              return { text: el, value: toKebabCase(el) };
            })}
          />
        </FormGroup>

        <FormGroup>
          <FormButton disabled={isBeingSubmitted}>Copy</FormButton>
        </FormGroup>
      </form>
    </>
  );
};

export default AddUpdateSOW;
