import React from "react";

const SideBySide = (props) => {
  const { left, right, weight } = props;

  return (
    <div className="row my-3">
      <div className="col-3">
        <span className={`font-weight-${weight ? "-" + weight : "bold"}`}>
          {left}
        </span>
      </div>
      <div className="col">{right}</div>
    </div>
  );
};

export default SideBySide;
