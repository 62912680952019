import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Breadcrumb = (props) => {
  const { showModuleName } = props;
  const { items } = props;
  const location = useLocation();
  const { currentModuleName } = useSelector((state) => state.params);

  return (
    <div className="page-titles">
      <ol className="breadcrumb">
        {items.map((el, index) => {
          return (
            <li
              key={Math.random()}
              className={`breadcrumb-item ${
                typeof el === "object" ? "" : "active"
              }`}
            >
              <Link
                to={`${typeof el === "object" ? el.path : location.pathname}`}
              >
                {`${typeof el === "object" ? el.title : el}`}
              </Link>
            </li>
          );
        })}
      </ol>
      {showModuleName && (
        <div>
          <strong>{currentModuleName}</strong>
        </div>
      )}
    </div>
  );
};

export default Breadcrumb;
