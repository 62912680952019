import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import BreadCrumb from "../../components/UI/Breadcrumb";
import FormGroup from "../../components/FormElements/FormGroup";
import Input from "../../components/FormElements/Input";
import ChangeTitle from "../../components/UI/ChangeTitle";
import FormButton from "../../components/Buttons/FormButton";
import AlertDismissible from "../../components/Alert/AlertDismissible";

const UpdateSeller = () => {
  const { sellerId } = useParams();
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    website: "",
    phone: "",
    facebook: "",
    instagram: "",
    youtube: "",
    twitter: "",
    linkedIn: "",
    isNameHidden: "",
    isEmailHidden: "",
    isPhoneHidden: "",
    isWebsiteHidden: "",
  });
  const [isBeingSubmitted, setIsBeingSubmitted] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [errorState, setErrorState] = useState("");

  const inputChangeHandler = (e, name, value) => {
    if (e) {
      ({ name, value } = e.target);
    }

    setFormState((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const formSubmitHandler = (e) => {
    setIsBeingSubmitted(true);
    setResponseMessage(false);
    setErrorState(false);
    e.preventDefault();

    axios
      .patch(`/sellers/${sellerId}`, formState)
      .then((res) => {
        setResponseMessage(res.data.msg);
      })
      .catch((err) => {
        if (err.response) {
          setErrorState(err.response.data.errors[0]);
        }
      })
      .finally(() => {
        setIsBeingSubmitted(false);
      });
  };

  useEffect(() => {
    axios
      .get(`/sellers/${sellerId}`)
      .then((res) => {
        const { seller } = res.data;
        setFormState(seller);
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          return setErrorState(err.response.data.errors[0]);
        }
        setErrorState("Failed to fetch seller");
      });
  }, [sellerId]);

  return (
    <>
      <ChangeTitle
        title="Update a seller"
        buttonTitle="View sellers"
        buttonPath={`/sellers`}
        buttonSmallIcon="list"
      />
      <BreadCrumb
        showSellerName
        items={[{ title: "Sellers", path: "/sellers" }, "Update a seller"]}
      />
      <AlertDismissible>{responseMessage}</AlertDismissible>
      <AlertDismissible onClose={() => setErrorState(false)} danger>
        {errorState}
      </AlertDismissible>

      <form onSubmit={formSubmitHandler}>
        <FormGroup
          label="Name *"
          withShowHideToggler
          togglerPropName={"isNameHidden"}
          onToggle={inputChangeHandler}
          togglerInitialState={formState.isNameHidden}
        >
          <Input
            type="Name *"
            name="name"
            placeholder="Name"
            value={formState.name || ""}
            onChange={inputChangeHandler}
          />
        </FormGroup>
        <FormGroup
          label="Email *"
          withShowHideToggler
          togglerPropName={"isEmailHidden"}
          onToggle={inputChangeHandler}
          togglerInitialState={formState.isEmailHidden}
        >
          <Input
            type="email"
            name="email"
            placeholder="hello@xyz.com"
            value={formState.email || ""}
            onChange={inputChangeHandler}
          />
        </FormGroup>
        <FormGroup
          label="Phone *"
          withShowHideToggler
          togglerPropName={"isPhoneHidden"}
          onToggle={inputChangeHandler}
          togglerInitialState={formState.isPhoneHidden}
        >
          <Input
            type="tel"
            name="phone"
            placeholder="Phone"
            value={formState.phone || ""}
            onChange={inputChangeHandler}
          />
        </FormGroup>
        <FormGroup
          label="Website"
          withShowHideToggler
          togglerPropName={"isWebsiteHidden"}
          onToggle={inputChangeHandler}
          togglerInitialState={formState.isWebsiteHidden}
        >
          <Input
            type="text"
            name="website"
            placeholder="https://www.hello.com"
            value={formState.website || ""}
            onChange={inputChangeHandler}
          />
        </FormGroup>
        <FormGroup label="Facebook">
          <Input
            type="text"
            name="facebook"
            value={formState.facebook || ""}
            onChange={inputChangeHandler}
            placeholder="Facebook"
          />
        </FormGroup>
        <FormGroup label="Instagram">
          <Input
            type="text"
            name="instagram"
            value={formState.instagram || ""}
            onChange={inputChangeHandler}
            placeholder="Instagram"
          />
        </FormGroup>
        <FormGroup label="Youtube">
          <Input
            type="text"
            name="youtube"
            value={formState.youtube || ""}
            onChange={inputChangeHandler}
            placeholder="Youtube"
          />
        </FormGroup>
        <FormGroup label="Twitter">
          <Input
            type="text"
            name="twitter"
            value={formState.twitter || ""}
            onChange={inputChangeHandler}
            placeholder="Twitter"
          />
        </FormGroup>
        <FormGroup label="Linked In">
          <Input
            type="text"
            name="linkedIn"
            value={formState.linkedIn || ""}
            onChange={inputChangeHandler}
            placeholder="Linked In"
          />
        </FormGroup>

        <FormGroup>
          <FormButton disabled={isBeingSubmitted}>Update</FormButton>
        </FormGroup>
      </form>
    </>
  );
};

export default UpdateSeller;
