import React from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const DropdownConfirmDeleteBtn = (props) => {
  const { text, btnText, yesBtnText, deleteConfirmed } = props;

  const submit = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-alert text-center">
            <h1>Are you sure?</h1>
            <p className="my-4">{text ? text : "You want to delete this?"}</p>
            <div className="text-center">
              <button className="btn mx-3 btn-primary" onClick={onClose}>
                No
              </button>
              <button
                className="btn mx-3 btn-primary"
                onClick={() => {
                  deleteConfirmed();
                  onClose();
                }}
              >
                {yesBtnText ? yesBtnText : "Yes, Delete it!"}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  return (
    <button onClick={submit} className="dropdown-item">
      {btnText ? btnText : "Delete"}
    </button>
  );
};

export default DropdownConfirmDeleteBtn;
