export default function getUrlParams(dParam) {
  var dPageURL = window.location.search.substring(1),
    dURLVariables = dPageURL.split("&"),
    dParameterName,
    i;

  for (i = 0; i < dURLVariables.length; i++) {
    dParameterName = dURLVariables[i].split("=");

    if (dParameterName[0] === dParam) {
      return dParameterName[1] === undefined
        ? true
        : decodeURIComponent(dParameterName[1]);
    }
  }
}
