import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router";

import BreadCrumb from "../../../components/UI/Breadcrumb";
import FormGroup from "../../../components/FormElements/FormGroup";
import Select from "../../../components/FormElements/Select";
import ChangeTitle from "../../../components/UI/ChangeTitle";
import FormButton from "../../../components/Buttons/FormButton";
import AlertDismissible from "../../../components/Alert/AlertDismissible";
import Constants from "../../../constants/constants";

const initialState = {
  status: "",
};

const AddTaskStatus = () => {
  const { taskId } = useParams();
  const [formState, setFormState] = useState(initialState);
  const [isBeingSubmitted, setIsBeingSubmitted] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [errorState, setErrorState] = useState("");

  const inputChangeHandler = (e, name, value) => {
    if (e) {
      ({ name, value } = e.target);
    }

    setFormState((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const formSubmitHandler = (e) => {
    setIsBeingSubmitted(true);
    setResponseMessage(false);
    setErrorState(false);
    e.preventDefault();

    axios
      .post(`/tasks/statuses/${taskId}`, formState)
      .then((res) => {
        setResponseMessage(res.data.msg);
        setFormState(initialState);
      })
      .catch((err) => {
        if (err.response) {
          setErrorState(err.response.data.errors[0]);
        }
      })
      .finally(() => {
        setIsBeingSubmitted(false);
      });
  };

  return (
    <>
      <ChangeTitle
        title="Add a task status"
        buttonTitle="View statuses"
        buttonPath={`/task-statuses/${taskId}`}
        buttonSmallIcon="list"
      />
      <BreadCrumb
        items={[
          { title: "Tasks", path: "/tasks" },
          { title: "Task statuses", path: `/task-statuses/${taskId}` },
          "Add a task status",
        ]}
      />
      <AlertDismissible>{responseMessage}</AlertDismissible>
      <AlertDismissible onClose={() => setErrorState(false)} danger>
        {errorState}
      </AlertDismissible>

      <form onSubmit={formSubmitHandler}>
        <FormGroup label="Status *">
          <Select
            firstEmpty
            name="status"
            value={formState.status}
            onChange={inputChangeHandler}
            options={Constants.validStatuses.map((el) => {
              //   return { text: el.name, value: el._id };
              return { text: el, value: el };
            })}
          />
        </FormGroup>

        <FormGroup>
          <FormButton disabled={isBeingSubmitted}>Add</FormButton>
        </FormGroup>
      </form>
    </>
  );
};

export default AddTaskStatus;
