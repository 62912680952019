export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const UPDATE_USER = "UPDATE_USER";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_CLEAR_ERRORS = "AUTH_CLEAR_ERRORS";

export const SET_CURRENT_MODULE = "SET_CURRENT_MODULE";
export const SET_CURRENT_SUB_MODULE = "SET_CURRENT_SUB_MODULE";
export const SET_CURRENT_PROJECT = "SET_CURRENT_PROJECT";
export const SET_CURRENT_SOW = "SET_CURRENT_SOW";
export const SET_CURRENT_TASK = "SET_CURRENT_TASK";
