// const env = "LOCAL";
const env = "CLOUD";

const Constants = {
  validStatuses: [
    "Awaiting for approval",
    "Approved by client",
    "Development in process",
    "Development complete",
    "Testing in progess",
    "Client approved",
    "Invoiced",
  ],
  validPublicStatuses: ["Client approved", "Invoiced"],
  defaultImageHost:
    env === "LOCAL"
      ? "http://localhost:5000/"
      : "https://projectshandlerbackend.webnapp.com.au/",
  defaultAPIHost:
    env === "LOCAL"
      ? "http://localhost:5000/api"
      : "https://projectshandlerbackend.webnapp.com.au/api",
  defaultFrontEndHost:
    env === "LOCAL"
      ? "http://127.0.0.1:5500/shahid-siddiqui/projects-manager/user-frontend/"
      : "https://projectshandler.webnapp.com.au/",
};

export default Constants;
