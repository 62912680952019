import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";

import Loader from "../../components/UI/Loader";
import ChangeTitle from "../../components/UI/ChangeTitle";
import AlertDismissible from "../../components/Alert/AlertDismissible";
import { useParams } from "react-router";
import isImageFile from "../../utils/is-image-file";
import Constants from "../../constants/constants";

const ViewTaskAttachments = () => {
  const { taskId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [attachmentsState, setAttachmentsState] = useState([]);
  const [responseMessage, setResponseMessage] = useState();
  const [errorState, setErrorState] = useState();

  const fetchTaskAttachments = useCallback(() => {
    setIsLoading(true);
    axios
      .get(`/tasks/attachments/${taskId}`)
      .then((res) => {
        setAttachmentsState(res.data.attachments);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [taskId]);

  const deleteAttachmentHandler = (taskFileId) => {
    setErrorState(false);
    setResponseMessage(false);
    axios
      .delete(`/tasks/attachments/${taskFileId}`)
      .then((res) => {
        setIsLoading(true);
        setResponseMessage(res.data.msg);
        fetchTaskAttachments();
      })
      .catch((err) => {
        if (err.response) {
          setErrorState(err.response.data.errors[0]);
        }
      });
  };

  useEffect(() => {
    fetchTaskAttachments();
  }, [fetchTaskAttachments]);

  return (
    <>
      <ChangeTitle
        title="View attachments"
        buttonTitle="View tasks"
        buttonPath="/tasks"
        buttonSmallIcon="list"
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <AlertDismissible>{responseMessage}</AlertDismissible>
          <AlertDismissible onClose={() => setErrorState(false)} danger>
            {errorState}
          </AlertDismissible>

          <div className="files">
            {attachmentsState.map((el) => {
              return (
                <div key={el.id} className="file">
                  <a
                    className="info"
                    href={Constants.defaultImageHost + el.path}
                    target="_blank"
                    without
                    rel="noreferrer"
                  >
                    <img
                      src={
                        isImageFile(el.filename)
                          ? "/icons/image.png"
                          : "/icons/file.png"
                      }
                      alt="icon"
                    />
                    <span>{el.filename}</span>
                  </a>
                  <div
                    className="actions"
                    onClick={() => deleteAttachmentHandler(el.id)}
                  >
                    <img src="/icons/delete.png" alt="delete" />
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};

export default ViewTaskAttachments;
