import React, { useEffect, useState, useRef, useCallback } from "react";
import { useLocation, NavLink } from "react-router-dom";
import $, { isEmptyObject } from "jquery";
import { logout } from "../store/actions/authActions";
import { useSelector, useDispatch } from "react-redux";

import { initNav } from "../shared/js/custom.min.js";

const Main = (props) => {
  const { children } = props;
  const location = useLocation();
  const [, setTitle] = useState("Home");
  const [isMobile] = useState(window.matchMedia("(max-width: 768px)").matches);
  const dropDownRef = useRef(null);
  const dispatch = useDispatch();
  const isDomainAdmin = useSelector(
    (state) => state?.auth?.user?.role === "domain-admin"
  );
  const { user } = useSelector((state) => state?.auth);
  const [navState] = useState([
    {
      title: "Home",
      icon: "home-3",
      to: "/",
      exact: true,
    },
    {
      title: "Projects",
      icon: "network",
      to: "/projects",
      exact: true,
    },
    {
      title: "Scope of works",
      icon: "network",
      to: "/sows",
      exact: true,
    },
    {
      title: "Modules",
      icon: "network",
      to: "/modules",
      exact: true,
    },
    {
      title: "Sub modules",
      icon: "network",
      to: "/sub-modules",
      exact: true,
    },
    {
      title: "Tasks",
      icon: "network",
      to: "/tasks",
      exact: true,
    },
    // {
    //   title: "Services",
    //   icon: "notepad",
    //   to: "/state-services",
    //   exact: true,
    //   subTitles: [
    //     {
    //       title: "Services of a state",
    //       icon: "notepad",
    //       to: "/state-services",
    //       exact: true,
    //     },
    //     {
    //       title: "All services",
    //       icon: "notepad",
    //       to: "/all-services",
    //       exact: true,
    //     },
    //   ],
    // },
  ]);

  const logoutHandler = () => {
    dispatch(logout());
  };

  const dropdownToggler = useCallback(() => {
    dropDownRef.current.classList.toggle("show");
  }, []);

  const windowClickHandler = (event) => {
    if (
      !(
        event.target.matches(".dropbtn") || event.target.matches(".dropbtn img")
      )
    ) {
      var dropdowns = document.getElementsByClassName("dropdown-content");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains("show")) {
          openDropdown.classList.remove("show");
        }
      }
    }
  };

  useEffect(() => {
    // Close the dropdown menu if the user clicks outside of it
    window.onclick = windowClickHandler;
    return () => {
      window.removeEventListener("click", windowClickHandler);
    };
  }, []);

  useEffect(() => {
    initNav();
    $("#preloader").fadeOut(500);
    $("#main-wrapper").addClass("show");

    setTitle(() => document.title);
    $("ul.mm-active, li.mm-active").removeClass("mm-active");
    $(".mm-active").parents("ul, li").addClass("mm-active");
    $("ul.mm-active")
      .addClass("mm-show")
      .siblings("a.has-arrow.ai-icon")
      .attr("aria-expanded", true);
    return () => {
      $(".nav-control").off();
    };
  }, [location]);

  useEffect(() => {
    $("#menu a").click(function () {
      if (isMobile) {
        $(".nav-control").click();
      }
    });
    return () => {
      $("#menu a").off();
    };
  }, [isMobile]);

  return (
    <div>
      {/************************* Main wrapper start *********************/}

      <div id="main-wrapper">
        {/*********************** Nav header start *********************/}

        <div className="nav-header">
          <a href="index.html" className="brand-logo">
            {/* <img
              className="logo-abbr"
              src={require("./images/logo.png")}
              alt=""
            />
            <img
              className="logo-compact"
              src={require("./images/logo-text.png")}
              alt=""
            />
            <img
              className="brand-title"
              src={require("./images/logo-text.png")}
              alt=""
            /> */}
          </a>

          <div className="nav-control">
            <div className="hamburger">
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </div>
          </div>

          {/************************* Nav header end *********************/}
        </div>

        {/************************* Header start *********************/}
        <div className="header">
          <div className="header-content">
            <nav className="navbar navbar-expand">
              <div className="collapse navbar-collapse justify-content-between">
                <div className="header-left">
                  <div className="dashboard_bar" id="dynamic-title"></div>
                </div>

                <ul className="navbar-nav header-right">
                  <li className="schedule-event-inner">
                    <button
                      id="event-list-toggler"
                      className="btn btn-primary btn-rounded hide"
                    >
                      LOREM
                    </button>
                  </li>
                  <li className="nav-item dropdown notification_dropdown">
                    <button
                      id="small-event-list-toggler"
                      className="nav-link bell bell-link primary"
                    >
                      <svg
                        fill="#222fb9"
                        version="1.1"
                        id="Capa_1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 512 512"
                      >
                        <g>
                          <g>
                            <path d="M492,236H276V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v216H20c-11.046,0-20,8.954-20,20s8.954,20,20,20h216    v216c0,11.046,8.954,20,20,20s20-8.954,20-20V276h216c11.046,0,20-8.954,20-20C512,244.954,503.046,236,492,236z" />
                          </g>
                        </g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                      </svg>
                      {/* <div class="pulse-css"></div> */}
                    </button>
                  </li>
                  <li className="nav-item dropdown header-profile">
                    <button
                      className="btn dropbtn nav-link"
                      onClick={dropdownToggler}
                    >
                      <img
                        src="/icons/flaticon/user.svg"
                        style={{ width: "30px" }}
                        alt="dropdown"
                      />
                    </button>
                    <div
                      id="myDropdown"
                      ref={dropDownRef}
                      style={{ top: "70%" }}
                      className="dropdown-content dropdown-menu dropdown-menu-right"
                    >
                      <h3 style={{ textAlign: "center" }}>{user?.name}</h3>
                      <button
                        className="dropdown-item ai-icon"
                        onClick={logoutHandler}
                      >
                        <svg
                          id="icon-logout"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-danger"
                          width="18"
                          height="18"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                          <polyline points="16 17 21 12 16 7"></polyline>
                          <line x1="21" y1="12" x2="9" y2="12"></line>
                        </svg>
                        <span className="ml-2">Logout </span>
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>

        {/************************* Header end ti-comment-alt *********************/}

        {/******************************* Sidebar start ***************************/}

        <div className="deznav">
          <div className="deznav-scroll">
            <ul className="metismenu" id="menu">
              {navState.map((el) => {
                if (el.onlyAdmin && isDomainAdmin) {
                  return null;
                }

                if (!isEmptyObject(el.subTitles)) {
                  return (
                    <li key={el.title}>
                      <a
                        className="has-arrow ai-icon"
                        href="/nothing"
                        onClick={(e) => e.preventDefault()}
                        aria-expanded="false"
                      >
                        <i className={`flaticon-381-${el.icon}`}></i>
                        <span className="nav-text">{el.title}</span>
                      </a>
                      <ul className="mm-collapse">
                        {el.subTitles.map(({ title, to, onlyAdmin }) => {
                          return (
                            <li key={title}>
                              <NavLink
                                exact
                                to={to}
                                className="ai-icon"
                                aria-expanded="false"
                                activeClassName="mm-active"
                              >
                                {title}
                              </NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                }
                return (
                  <li key={el.title}>
                    <NavLink
                      exact
                      to={el.to}
                      className="ai-icon"
                      aria-expanded="false"
                      activeClassName="mm-active"
                    >
                      <i className={`flaticon-381-${el.icon}`}></i>
                      <span className="nav-text">{el.title}</span>
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>

        {/******************************* Sidebar start ***************************/}
      </div>

      <div className="content-body">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
