import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import BreadCrumb from "../../components/UI/Breadcrumb";
import FormGroup from "../../components/FormElements/FormGroup";
import Input from "../../components/FormElements/Input";
import ChangeTitle from "../../components/UI/ChangeTitle";
import FormButton from "../../components/Buttons/FormButton";
import AlertDismissible from "../../components/Alert/AlertDismissible";
import Loader from "../../components/UI/Loader";
import Select from "../../components/FormElements/Select";

const initialState = { name: "", description: "" };

const AddUpdateSOW = ({ mode }) => {
  const { sowId } = useParams();
  const [projectsState, setProjectsState] = useState([]);
  const [formState, setFormState] = useState(initialState);
  const [isLoading, setIsLoading] = useState(true);
  const [isBeingSubmitted, setIsBeingSubmitted] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [errorState, setErrorState] = useState("");

  let title = `${mode === "edit" ? "Update a" : "Add"} scope of work`;

  const inputChangeHandler = (e, name, value) => {
    if (e) {
      ({ name, value } = e.target);
    }

    setFormState((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const formSubmitHandler = async (e) => {
    setIsBeingSubmitted(true);
    setResponseMessage(false);
    setErrorState(false);
    e.preventDefault();

    let res;
    try {
      if (mode === "edit") {
        res = await axios.patch(`/sows/${sowId}`, formState);
      } else {
        res = await axios.post("/sows", formState);
        setFormState(initialState);
      }
      setResponseMessage(res.data.msg);
    } catch (err) {
      console.log(err);
      if (err.response) {
        setErrorState(err.response.data.errors[0]);
      }
    }

    setIsBeingSubmitted(false);
  };

  useEffect(() => {
    const fetchMyAPI = async (mode) => {
      try {
        if (mode === "edit") {
          const res = await axios.get(`/sows/${sowId}`);
          const { sow } = res.data;
          setFormState(sow);
        } else {
          const res = await axios.get(`/projects`);
          const { projects } = res.data;
          setProjectsState(projects);
        }

        setIsLoading(false);
      } catch (err) {
        console.log(err);
        if (err.response) {
          return setErrorState(err.response.data.errors[0]);
        }
        setErrorState("Failed to fetch scope of work");
      }
    };

    fetchMyAPI(mode);
  }, [sowId, mode]);

  return (
    <>
      <ChangeTitle
        title={title}
        buttonTitle="View scope of works"
        buttonPath={`/sows`}
        buttonSmallIcon="list"
      />
      <BreadCrumb
        showSOWName={mode === "edit"}
        items={[{ title: "Scope of works", path: "/sows" }, title]}
      />
      <AlertDismissible>{responseMessage}</AlertDismissible>
      <AlertDismissible onClose={() => setErrorState(false)} danger>
        {errorState}
      </AlertDismissible>

      {isLoading ? (
        <Loader />
      ) : (
        <form onSubmit={formSubmitHandler}>
          {mode !== "edit" && (
            <FormGroup label="Project *">
              <Select
                firstEmpty
                name="projectId"
                value={formState.projectId}
                onChange={inputChangeHandler}
                options={projectsState.map((el) => {
                  return { text: el.name, value: el.id };
                })}
              />
            </FormGroup>
          )}

          <FormGroup label="Name *">
            <Input
              type="text"
              name="name"
              placeholder="Name"
              value={formState.name || ""}
              onChange={inputChangeHandler}
            />
          </FormGroup>

          <FormGroup label="Description *">
            <Input
              textarea
              type="text"
              name="description"
              placeholder="Description"
              value={formState.description || ""}
              onChange={inputChangeHandler}
            />
          </FormGroup>

          <FormGroup>
            <FormButton disabled={isBeingSubmitted}>
              {mode === "edit" ? "Update" : "Add"}
            </FormButton>
          </FormGroup>
        </form>
      )}
    </>
  );
};

export default AddUpdateSOW;
